const CloseButton = ({ onClick }) => (
  <button onClick={onClick} className="close-btn">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <rect y="14.3999" width="20.3647" height="0.848528" transform="rotate(-45 0 14.3999)" fill="black" />
      <rect x="14.4" y="15" width="20.3647" height="0.848528" transform="rotate(-135 14.4 15)" fill="black" />
    </svg>
  </button>
)

export default CloseButton

export const Arrow = () => (
  <i className="arrow">
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
      <path d="M1 1L5.5 6L10 1" stroke="black" />
    </svg>
  </i>
)
