// use Globale E Script
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

const useScript = ({ countryCode, currency, trigger }) => {
  const router = useRouter()

  useEffect(() => {
    if (!countryCode || !currency || !trigger.trigger) {
      return
    }
    // dont run for US
    if (countryCode === 'US') {
      return
    }

    if (document.getElementById('GLBE_PARAMS')) {
      return
    }
    // delay 1 second to allow script to load
    if (trigger?.trigger) {
      const timer = setTimeout(() => {
        const script = document.createElement('script')
        script.defer = true
        script.innerHTML = `
          GLBE_PARAMS = {
            appUrl: "https://crossborder-integration.global-e.com/",
                pixelUrl: "https://utils.global-e.com",
                pixelEnabled: true,
                geAppUrl: "https://web.global-e.com/",
                env: "Production",
                geCDNUrl: "https://webservices.global-e.com/merchant/freeShippingBanner?merchantId=30000289&country=${countryCode}&currency=${currency}&culture=&cb=2821341",
                apiUrl: "https://api.global-e.com/",
                emi: "ttrfz",
                mid: "30000289",
                hiddenElements: ".ge-hide,.afterpay-paragraph,form[action='https://payments.amazon.com/checkout/signin']",
                countryCode: "",
                countryName: "",  
                currencyCode: "",
                currencyName: "",
                locale: "en-US",
                operatedCountries: [],
                allowedCountries: []
          };
        `
        script.id = 'GLBE_PARAMS'
        document.head.appendChild(script)

        // also add another script tag for the actual script
        const script2 = document.createElement('script')
        script2.defer = true
        script2.src = 'https://crossborder-integration.global-e.com/js/app.js'
        document.head.appendChild(script2)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [countryCode, currency, trigger])

  // const banner = typeof window !== 'undefined' ? document.getElementsByClassName('ge-free-shipping-container') : []

  const banner = useMemo(() => {
    if (typeof window !== 'undefined') {
      return document.getElementsByClassName('ge-free-shipping-container')
    } else {
      return []
    }
  }, [])

  useEffect(() => {
    if (banner.length > 1 && trigger?.trigger) {
      for (let i = 0; i < banner.length; i++) {
        if (i === 0) {
          banner[i].style.display = 'block'
        } else {
          banner[i].style.display = 'none'
        }
      }
    }

    if (countryCode === 'US') {
      for (let i = 0; i < banner.length; i++) {
        banner[i].style.display = 'none'
      }
    }
  }, [countryCode, router.asPath, trigger, banner, banner.length])
}

export default useScript
