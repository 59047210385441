const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
const previewAccessToken = process.env.NEXT_PRIVATE_CONTENTFUL_PREVIEW_TOKEN
const environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ?? "master"

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function fetchContentfulGraphQL(query, preview = false, variables = {}) {
  let isFirst = true
  let response
  let requestCount = 0

  // Retry API request 5 times if status is not 200
  while (requestCount < 5 && response?.status !== 200) {
    if (!isFirst) {
      console.log('Error while contentful fetching, sleeping 30s...', query)
      await sleep(30000)
      console.log('Wake up')
      console.log(response)
    }

    response = await fetch(`https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${preview ? previewAccessToken : accessToken}`
      },
      body: JSON.stringify({ query, variables })
    })

    if (response.errors) {
      console.log('\x1b[31m', '###RESPONSE ERRORS' + JSON.stringify(response.errors))
    }

    isFirst = false
    requestCount += 1
  }

  return response.json()
}

export default fetchContentfulGraphQL
