import fetchContentfulGraphQL from "./fetchContentfulGraphQL"

async function fetchUpsell(preview) {
    const response = await fetchContentfulGraphQL(
        `query {
            upsellProduct(id: "6m9G7nNo8ZSNp2lvTErATm") {
              title
              showProducts   
            }
          }          
        `,
        preview
    );
    
    return response;
}

export default fetchUpsell