import fetchProduct from "./fetchProduct";


async function fetchProductsByHandle(handles , country) {
    const products = await Promise.all(handles.map(handle => fetchProduct(handle, false , country)));

    return products.filter(p => !!p);
}


export default fetchProductsByHandle
