import styled from '@emotion/styled'
import { theme, mq } from '@theme'
import UpsellProductCard from '../UpsellProductCard'
import { useEffect, useState } from 'react'
import { ReactSmartScrollerHorizontal } from '../SmartScroller'

const Root = styled.div`
  position: relative;
  margin-top: 10px;
  // ${theme.size.l('margin-bottom')}
`
const MainGrid = styled.div`
  position: relative;
  display: grid;
  overflow-x: auto;
  scroll-snap-type: x proximity;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .css-1uf1r8x {
    display: none !important;
  }

  .kSMAC {
    height: 2px !important;
    background-color: black !important;
  }
  .gmtmqV {
    height: 2px !important;
    background-color: black !important;
  }

  .cmtWj {
    margin-bottom: ${(props) => (props.isSidebar ? '15px' : '5px')} !important;

    &::-webkit-scrollbar {
      display: none !important;
    }
  #custom-scroll-0 {
    padding-bottom: ${(props) => (props.isSidebar ? '5px' : '5px')} !important;
  }

  .cVmQYF {
    margin-bottom: ${(props) => (props.isSidebar ? '15px' : '40px')} !important;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  @media (max-width: 768px) {
    .jWCrSi {
      display: block !important;
    }
    .hvJMgY {
      display: block !important;
    }
    .sc-Axmtr {
      display: block !important;
    }
    #grid {
      & > div:last-of-type {
        margin-right: ${(props) => (props.singleProduct ? '0px' : '30px')} !important;
      }
    }
  }

  @media (max-width: 550px) {
    .cmtWj {
      margin-bottom: ${(props) => (props.isSidebar ? '5px' : '5px')} !important;
    #custom-scroll-0 {
      padding-bottom: ${(props) => (props.isSidebar ? '5px' : '5px')} !important;
    }

    .css-g806h8 {
      margin-bottom: -27px !important;

      // only show first 5 children
      & > div:nth-of-type(n + 6) {
        display: none;
      }
    }
  }
`
const InnerGrid = styled.div`
  z-index: 0;
  display: flex;
  ${theme.size.gutter('grid-column-gap')}
  & > div {
    width: ${(props) => (props.isSidebar ? '410px' : '500px')};
    background-color: #f5f5f5;
    padding: 10px;
  }

  display: grid;
  grid-auto-flow: column;

  @media (max-width: 550px) {
    & > div {
      ${(props) => (props.singleProduct ? 'min-width: 100%' : '')};
      width: ${(props) => (props.isSidebar ? '400px' : '390px')};
    }
  }

  @media (max-width: 450px) {
    & > div {
      ${(props) => (props.singleProduct ? 'min-width: 100%' : '')};
      width: ${(props) => (props.isSidebar ? '300px' : '290px')};
    }
  }
`

const ScrollWrap = styled.div`
  position: relative;
  width: 100vw;
  ${mq['lg']} {
    width: 100%;
  }

  @media (max-width: 450px) {
    width: unset;
  }
`
const UpsellSlider = ({ upsellProducts, isInCartPage, isSidebar }) => {
  const renderUpsellProducts = () => {
    return upsellProducts.map((item, i) => {
      return (
        <div key={i}>
          <UpsellProductCard product={item} isInCartPage={isInCartPage} />
        </div>
      )
    })
  }

  return (
    <Root id="carausal">
      <ScrollWrap>
        <MainGrid id="main-track" isSidebar={isSidebar}>
          {upsellProducts?.length === 1 && (
            <InnerGrid id="grid" itemsNumber={upsellProducts?.length} isInCartPage={isInCartPage} isSidebar={isSidebar}>
              {renderUpsellProducts()}
            </InnerGrid>
          )}
          {upsellProducts?.length >= 2 && (
            <ReactSmartScrollerHorizontal
              draggable={true}
              trackProps={{
                height: 2
              }}
              thumbProps={{
                height: 2
              }}
            >
              <InnerGrid
                id="grid"
                itemsNumber={upsellProducts?.length}
                isInCartPage={isInCartPage}
                isSidebar={isSidebar}
              >
                {renderUpsellProducts()}
              </InnerGrid>
            </ReactSmartScrollerHorizontal>
          )}
        </MainGrid>
      </ScrollWrap>
    </Root>
  )
}

export default UpsellSlider
