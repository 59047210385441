import { storefront } from '.'

export async function displayCart(cartId) {
  const response = await storefront(displayCartQuery, { cartId })

  return {
    statusCode: 200,
    body: response.data
  }
}

const displayCartQuery = `
    query getCart($cartId: ID!) {
        cart(id: $cartId) {
        id
        lines(first: 100) {
            edges {
            node {
                id
                quantity
                attributes { 
                    key
                    value
                }
                merchandise {
                ... on ProductVariant {
                    id
                    sku
                    title
                    image {
                    url(transform: {maxWidth: 500})
                    altText
                    }
                    priceV2 {
                    amount
                    currencyCode
                    }
                    compareAtPrice {
                    amount
                    currencyCode
                    }
                    product {
                    id
                    title
                    handle
                    totalInventory
                    options (first: 5) {
                        name
                        values
                      }
                    images(first: 1) {
                        edges {
                        node {
                            url(transform: {maxWidth: 500})
                            altText
                        }
                        }
                    }
                    }
                    selectedOptions {
                        name
                        value
                      }
                      quantityAvailable
                }
                }
            }
            }
        }
        estimatedCost {
            totalAmount {
            amount
            currencyCode
            }
            subtotalAmount {
            amount
            currencyCode
            }
            totalTaxAmount {
            amount
            currencyCode
            }
            totalDutyAmount {
            amount
            currencyCode
            }
        }
        buyerIdentity {
            countryCode
          }
        }
    }
`
