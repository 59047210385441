import { useState, useEffect } from 'react'
import fetchUpsell from '@/data/contentful/fetchUpsell'
import fetchProductsForUpsell from '@/data/shopify/fetchProductsForUpsell'
import fetchProducts from '@/data/shopify/fetchProducts'

const useShowUpSell = (countryCode) => {
  const [upSell, setUpSell] = useState({})
  const [loading, setLoading] = useState(true)
  const [upsellProducts, setUpsellProducts] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUpsell()
        setUpSell(data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {

    const fetchUpsellProducts = async (Code) => {
        console.log('fetchUpsellProducts', Code)
      const upsellHandle = 'upsell'
      const responses = await Promise.all([fetchProductsForUpsell(upsellHandle, Code)])
      const upsell = responses[0]
      if (upsell) {
        let upsellProducts = [...upsell.map((group) => group.products)].flat() // PUT THIS IN A STATE

        const productsWithRelated = await Promise.all(
          upsellProducts?.map(async (product) => {
            const TITLE_SEPARATOR = ' - '
            const query = `title:'${product.title} - '* `

            const relatedProducts = await fetchProducts({ query, Code })
            const relatedProductsWithoutSets = relatedProducts.filter(
              (p) => p.originalTitle.split(TITLE_SEPARATOR)[0] === product.title
            )
            return {
              ...product,
              relatedProducts: relatedProductsWithoutSets
            }
          })
        )
        upsellProducts = productsWithRelated
        return upsellProducts
      } else return null
    }

    const fetchData = async () => {
      try {
        if (countryCode) {
          const data = await fetchUpsellProducts(countryCode)
          setUpsellProducts(data)
          console.log(data, 'DATA')
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [countryCode])

  return { upSell, loading, upsellProducts }
}

export default useShowUpSell
