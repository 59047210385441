import React, { createContext, useState, useEffect } from 'react'

import { graphqlQuery } from '@/data/shopify/services/shopifyApolloClient'
import gql from 'graphql-tag'
import useCart from '@/data/shopify/useCart'
import { fetchOrCreateCheckout } from '@/data/shopify/services/checkoutService'
import useScript from './useScript'

// get countries list from Shopify
async function getCountries() {
  const { data } = await graphqlQuery({
    query: gql`
      query {
        localization {
          availableCountries {
            isoCode
            name
          }
        }
      }
    `
  })

  return data
}

// get testing Costo Rico get currency
async function getCurrency(countryCode) {
  if (!countryCode) {
    return null
  }
  const { data } = await graphqlQuery({
    query: gql`
      query @inContext(country: ${countryCode}) {
        localization {
          country {
            currency {
              isoCode
              name
              symbol
            }
            isoCode
            name
          }
        }
      }
    `
  })

  return data
}

const GlobalEContext = createContext(null)

function GlobalEProvider({ children, trigger }) {
  const [data, setData] = useState({
    shownPopup: false,
    loading: false,
    country: {
      label: 'United States',
      value: 'US'
    },
    currency: {
      isoCode: 'USD',
      name: 'US Dollar',
      symbol: '$'
    },
    countriesList: null
  })
  
  const { cart, setCart } = useCart() ?? {}

  useScript({ 
    countryCode: data.country?.value?.toString(), 
    currency: data.currency?.isoCode?.toString(), 
    trigger:{trigger} 
  })
  
  const updateCurrency = async (countryValue) => {
    try {
      if (!countryValue) {
        return null
      }
      const currency = await getCurrency(countryValue)
      setData((prev) => ({ ...prev, currency: currency.localization.country.currency }))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCountry = async () => {
    const res = await fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
    const data = await res.json()

    const modifyLabel = data.userSelectValue.label.split(' ').slice(1).join(' ')
    return { ...data.userSelectValue, label: modifyLabel }
  }

  const fetchCountriesList = async () => {
    const countries = await getCountries()
    const modifyData = countries.localization.availableCountries.map((country) => {
      return {
        label: country.name,
        value: country.isoCode
      }
    })
    return modifyData
  }

  useEffect(() => {
    const controller = new AbortController()
    const fetchData = async () => {
      try {
        setData({ ...data, loading: true })

        const countries = await fetchCountriesList()
        const selectedCountry = await fetchCountry()
        // get currency
        const currency = await getCurrency(selectedCountry.value)

        setData({
          ...data,
          countriesList: countries,
          country: localStorage.getItem('selectedCountry')
            ? JSON.parse(localStorage.getItem('selectedCountry'))
            : selectedCountry,
          loading: false,
          shownPopup: localStorage.getItem('shownPopup') ? false : true,
          currency: currency.localization.country.currency
        })
        // get from local storage
        typeof window !== 'undefined' && localStorage.setItem('shownPopup', true)
        if (!localStorage.getItem('selectedCountry')) {
          typeof window !== 'undefined' && localStorage.setItem('selectedCountry', JSON.stringify(selectedCountry))
        }

      } catch (error) {
        console.log(error)
        controller.abort()
      }
    }
    if (trigger) {
      fetchData()
    }

    return () => {
      controller.abort()
    }
  }, [])

  // update currency when country change
  useEffect(() => {
    trigger && updateCurrency(data.country?.value)
  }, [data.country])

  useEffect(() => {
    if (!trigger) {
      return
    }
    const timeout = setTimeout(() => {
      if (cart?.lineItems?.length > 0) {
        fetchOrCreateCheckout(false, data.country?.value).then((value) => {
          const totalPrice = value.lineItems.reduce((total, item) => total + parseInt(item.variant.priceV2.amount), 0)
          setCart((prev) => {
            return {
              ...prev,
              lineItems: value.lineItems,
              internationPricing: {
                totalPrice: totalPrice,
                variation: value.lineItems
              }
            }
          })
        })
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [data.country?.value, cart?.lineItems])

  return <GlobalEContext.Provider value={{ data, setData }}>{children}</GlobalEContext.Provider>
}

function useGlobalE() {
  const context = React.useContext(GlobalEContext)
  if (context === undefined) {
    throw new Error('useGlobalE must be used within a GlobalEProvider')
  }
  return context
}

export { GlobalEProvider, useGlobalE }
