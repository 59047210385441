import shopifyApolloClient from './shopifyApolloClient'
import { removeEdges } from '../mapHelpers'

import { fetchCheckoutQuery } from './graphql/fetchCheckoutQuery'
import { checkoutCreateMutation } from './graphql/checkoutCreateMutation'
import { checkoutLineItemsReplaceMutation } from './graphql/checkoutLineItemsReplaceMutation'
import { mapCheckoutProduct } from '../mapHelpers'
import sleep from '@/helpers/sleep'

function checkoutIdKey() {
  return 'checkoutId_'
}

const checkoutEntity = async (checkout) => {
  
  let initMap = {
    totalPrice : 0,
    variation : [],
  }

  const lineItems = removeEdges(checkout?.lineItems).map((lineItem) => {
    // add them in proper way convert to number  
    initMap.totalPrice += parseFloat(lineItem.variant.priceV2.amount) 

    initMap.variation.push({ 
      id: lineItem.variant.id,
      amount: lineItem.variant.priceV2.amount,
      currencyCode: lineItem.variant.priceV2.currencyCode
    })

    const mappedProduct = mapCheckoutProduct(lineItem.variant.product)
    const variant = {
      ...lineItem.variant,
      size: { id: lineItem.variant.title, label: lineItem.variant.title }
    }

    return {
      ...lineItem,
      variant: {
        ...variant,
        productId: mappedProduct.id,
        productHandle: mappedProduct.handle,
        product: mappedProduct
      },
      product: mappedProduct
    }
  })

  return {
    id: checkout.id,
    order: checkout.order,
    subtotalPrice: checkout?.subtotalPriceV2, // change
    totalPrice: checkout?.totalPriceV2,
    shippingPrice: checkout?.shippingLine?.priceV2 ?? null, // nullable! if shippingMethod was not picked in current session, then it's null
    lineItems,
    currencyCode: checkout?.currencyCode,
    internationPricing :  initMap,
    checkoutUrl: checkout?.webUrl, // url to Shopify checkout,
    customAttributes: checkout.customAttributes
  }
}

async function createCheckout(input = {} , country) {
  const results = await shopifyApolloClient().mutate({
    mutation: checkoutCreateMutation,
    variables: {
      input,
      country
    }
  })

  const { data } = results
  const id = data?.checkoutCreate?.checkout?.id
  localStorage.setItem(checkoutIdKey(), id)

  return checkoutEntity(data?.checkoutCreate?.checkout)
}

export async function fetchCheckout(id , country) {
  const results = await shopifyApolloClient().query({
    query: fetchCheckoutQuery,
    variables: {
      id,
      country
    }
  })

  if (results.data.node === null) {
    return null
  }

  return checkoutEntity(results.data.node)
}

export async function fetchOrCreateCheckout(shouldCreate = true , countryCode) {
  const id = localStorage.getItem(checkoutIdKey())
  
  const country = localStorage.getItem('selectedCountry');
  // const {label , value} = JSON.parse(country) || "US";
  // if no country then set default US
  const {label , value} = JSON.parse(country) || {label : "United States" , value : "US"};

  if (!value) {
    throw new Error('No Value for Country to  use fetchOrCreateCheckout() first.')
  }

  if (id) {
    let checkout = await fetchCheckout(id , countryCode ? countryCode : value)
    if (!checkout) {
      await sleep(1000)
      checkout = await fetchCheckout(id , countryCode ? countryCode : value)
    }

    if (checkout) {
      if (checkout.order && checkout.order.financialStatus === 'PAID') {
        return createCheckout({}, countryCode ? countryCode : value)
      }
      return checkout
    }
  }

  if (shouldCreate) {
    return createCheckout({}, countryCode ? countryCode : value)
  } else {
    return null
  }
}

export async function replaceLineItems(lineItems = [], signal = null) {
  // console.log('###replaceLineItems lineItems', lineItems)

  //   let t1 = new Date()

  const checkoutId = localStorage.getItem(checkoutIdKey())
  const country = localStorage.getItem('selectedCountry');
  const {label , value} = JSON.parse(country) || {};

  if (!checkoutId) {
    throw new Error('No checkout id detected! Please use fetchOrCreateCheckout() first.')
  }

  if (!value) {
    throw new Error('No Value for Country to  use fetchOrCreateCheckout() first.')
  }

  const variables = {
    checkoutId,
    lineItems
  }

  try {
    let results = await shopifyApolloClient().mutate({
      mutation: checkoutLineItemsReplaceMutation,
      variables,
      country : value,
      context: {
        fetchOptions: {
          signal,
        }
      },
      fetchPolicy: 'no-cache'
    })
    // console.log('######## results', results)

    // console.log('###replaceLineItems results', results.data.checkoutLineItemsReplace.checkout.lineItems)
    // let t2 = new Date()
    // console.log(`replaceLineItems ${t2 - t1}ms`)
    return checkoutEntity(results?.data?.checkoutLineItemsReplace?.checkout)
  } catch (error) {
    console.log(error)
    return
  }
}
