import React, { useState, useEffect } from 'react'
import useCart from '@/data/shopify/useCart'
import styled from '@emotion/styled'
import { useUI } from '@/hooks/UI'

import { theme, mq, lin } from '@theme'

import Container from '@/components/Container'
import { ButtonLogo, ButtonBlock } from '@button'
import IconBurger from '@/components/_icons/IconBurger'
import IconBag from '@/components/_icons/IconBag'
import IconAccount from '@/components/_icons/IconAccount'
import IconLoyalty from '@/components/_icons/IconLoyalty'
import SearchButton from '@/components/NavBar/SearchButton'
import { useRouter } from 'next/router'
import { useCustomer } from '@/hooks/Customer'
import { useCartContext } from '@/contexts/CartContext'
import Cart from '../Cartt'

const Root = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: all 300ms;
  mix-blend-mode: difference;
  color: white;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -8px;
  margin-right: -8px;
  ${theme.size.menuBarHeight('height')}
  svg {
    fill: currentColor;
  }
`

const Group = styled.div`
  display: inline-grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  pointer-events: all;
  align-items: center;
  ${lin('grid-column-gap', 4, 20)}
  .fixed-width {
    width: 36px;
  }

  @media (min-width: 1024px) {
    button {
      &:hover {
        opacity: 0.5;
      }
    }
  }
`
const ButtonInner = styled.div`
  white-space: nowrap;
  ${theme.font.caps06}
  line-height: 1;
  transition: all 200ms;
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 4px;

  @media (min-width: 1024px) {
      &:hover {
        opacity: 0.5;
      }
  }
`
const HideOnMobile = styled.div`
  display: none;
  ${mq['lg']} {
    display: inline-flex;
  }

  @media (min-width: 1024px) {
    a {
      &:hover {
        opacity: 0.5;
      }
    }
  }
`
const HideOnDesktop = styled.div`
  display: inline-flex;
  ${mq['lg']} {
    display: none;
  }
`
const Micro = styled.span`
  ${theme.font.body03}
  position: absolute;
  top: 0;
  right: 0;
`
const OverlayContainer = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9995;
  overflow-y: scroll;
  cursor: pointer;
  height: 100vh;
  overflow: hidden;
  display: block;
  transition: 0.15s ease-in;
  visibility: ${({ cartToggled }) => (cartToggled ? 'visible' : 'hidden')};
  opacity: ${({ cartToggled }) => (cartToggled ? 1 : 0)};
`

// CART BUTTON

const CartButton = ({ onClick }) => {
  const { cart } = useCart() || {}

  const { cartId, setCartId, cartToggled, setCartToggled, setProductMini, productmini, setProductCount, productCount } =
    useCartContext()

  let itemsInBag = 0
  if (cart && cart.lineItems?.length > 0) {
    cart.lineItems.forEach((i) => {
      itemsInBag = itemsInBag + i.quantity
    })
  }

  return (
    <ButtonBlock onClick={onClick}>
      <ButtonInner>
        <HideOnDesktop>
          <IconBag />
          {productCount > 0 && <Micro>{productCount}</Micro>} 
        </HideOnDesktop>
        <HideOnMobile>BAG {productCount > 0 && `(${productCount})`}</HideOnMobile>{' '}
      </ButtonInner>
    </ButtonBlock>
  )
}

const NavBar = () => {
  const { openNav, openCart } = useUI()
  const [headerPosition, setHeaderPosition] = useState('translateY(0)')
  const [headerTheme, setHeaderTheme] = useState('Light')
  const [toggleMobileNav, setMobileToggleNav] = useState(false)
  const { cartToggled, setCartToggled } = useCartContext()

  const overlayControl = (e) => {
    if (e.target.id === 'overlay-container') {
      setCartToggled(false)
    }
  }

  useEffect(() => {
    cartToggled ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible')
  }, [cartToggled])

  const Customer = useCustomer()

  const firstName = Customer?.CustomerFirstName

  return (
    <>
      <Root>
        <Container isWide>
          <Inner>
            <Group>
              <ButtonBlock onClick={openNav}>
                <ButtonInner>
                  <IconBurger />
                  <HideOnMobile className={'fixed-width'}>Shop</HideOnMobile>
                </ButtonInner>
              </ButtonBlock>
              <ButtonLogo id="icon" href={'/'} />
            </Group>

            <Group>
              <ButtonBlock href={'/loyalty'}>
                <ButtonInner>
                  <HideOnDesktop>
                    <IconLoyalty />
                  </HideOnDesktop>
                </ButtonInner>
              </ButtonBlock>

              <SearchButton />

              <ButtonBlock href={'https://shop.splits59.com/account'}>
                <ButtonInner>
                  <HideOnDesktop>
                    <IconAccount />
                  </HideOnDesktop>
                  <HideOnMobile>{firstName ? `Hi, ${firstName}` : 'Account'}</HideOnMobile>
                </ButtonInner>
              </ButtonBlock>

              <HideOnMobile>
                <ButtonBlock href={'/loyalty'}>
                  <ButtonInner>Loyalty</ButtonInner>
                </ButtonBlock>
              </HideOnMobile>

              <CartButton onClick={() => setCartToggled(!cartToggled)} cartToggled={cartToggled} theme={headerTheme} />
            </Group>
          </Inner>
        </Container>
      </Root>
      <OverlayContainer onClick={overlayControl} cartToggled={cartToggled} id={'overlay-container'}>
        <Cart />
      </OverlayContainer>
    </>
  )
}

export default NavBar
