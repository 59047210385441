export const colors = {
    primary: 'black',
    secondary: '#27ae60',
    typography: '#2c3e50',
    red: '#e74c3c',
    white: '#ffffff',
    black: '#000000',
    peterRiver: '#3498db',
    sunflower: '#f1c40f',
    gray: {
        lightGray: '#ecf0f1',
        mediumGray: '#bdc3c7',
        darkGray: '#9ca2a7',
    }
}

export const isServer = () => typeof window === 'undefined'

const checkAgent = (agentName) => !isServer()
  ? navigator.userAgent.includes(agentName)
  : false;

export const isIpad = () => checkAgent('iPad')
export const isIOS = () => checkAgent('iPhone') || isIpad()
export const isAndroid = () => checkAgent('Android')
export const isMsEdge = () => checkAgent('Edge')
export const isMacOs = () => checkAgent('Mac')
export const isMobile = () => isAndroid() || isIOS()
