import React from 'react'

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.48 56.28">
    <path d="M8.33,28a5.69,5.69,0,0,0,1.74,2,7.51,7.51,0,0,0,2.54,1.16,12.15,12.15,0,0,0,3,.38,14.38,14.38,0,0,0,2.27-.18,7.74,7.74,0,0,0,2.27-.68,4.88,4.88,0,0,0,1.76-1.39,3.36,3.36,0,0,0,.71-2.24,3.16,3.16,0,0,0-.93-2.37,7.39,7.39,0,0,0-2.45-1.51,25.22,25.22,0,0,0-3.43-1.06c-1.27-.3-2.57-.63-3.88-1A34.21,34.21,0,0,1,8,19.88,14,14,0,0,1,4.6,18a9,9,0,0,1-2.45-2.9,8.88,8.88,0,0,1-.93-4.21A9.54,9.54,0,0,1,2.4,6.07a11,11,0,0,1,3.1-3.4,13.5,13.5,0,0,1,4.34-2A18.47,18.47,0,0,1,14.68,0a22.86,22.86,0,0,1,5.41.63,13.36,13.36,0,0,1,4.61,2,10.16,10.16,0,0,1,3.2,3.6,10.94,10.94,0,0,1,1.19,5.32H21.43a6.36,6.36,0,0,0-.68-2.67,4.35,4.35,0,0,0-1.54-1.62A6.43,6.43,0,0,0,17,6.4a13.5,13.5,0,0,0-2.7-.25,9.46,9.46,0,0,0-1.91.2,5,5,0,0,0-1.74.71A4.39,4.39,0,0,0,9.38,8.32a3.46,3.46,0,0,0-.5,1.91,3.18,3.18,0,0,0,.4,1.71,3.57,3.57,0,0,0,1.59,1.21,21.1,21.1,0,0,0,3.28,1.11q2.08.56,5.46,1.41c.68.14,1.61.38,2.8.73A13.32,13.32,0,0,1,26,18.09a10.68,10.68,0,0,1,3,3.1,8.72,8.72,0,0,1,1.29,5,10.88,10.88,0,0,1-1,4.59,10,10,0,0,1-2.85,3.65,13.43,13.43,0,0,1-4.68,2.4,22.15,22.15,0,0,1-6.48.85A22.54,22.54,0,0,1,9.52,37a14.47,14.47,0,0,1-4.94-2.29,11.42,11.42,0,0,1-3.4-4A12.11,12.11,0,0,1,0,24.89H7.67A6.68,6.68,0,0,0,8.33,28Z" />
    <path d="M48.78.86a14.84,14.84,0,0,1,5.75,1,10.65,10.65,0,0,1,3.85,2.59,10,10,0,0,1,2.17,3.68,13.73,13.73,0,0,1,0,8.55,9.76,9.76,0,0,1-2.17,3.7A10.51,10.51,0,0,1,54.53,23a14.84,14.84,0,0,1-5.75,1H40.47V36.9H32.56V.86ZM46.62,17.79a17.57,17.57,0,0,0,2.62-.2,6.21,6.21,0,0,0,2.21-.78A4.31,4.31,0,0,0,53,15.17a5.54,5.54,0,0,0,.58-2.77A5.54,5.54,0,0,0,53,9.63,4.21,4.21,0,0,0,51.45,8a5.73,5.73,0,0,0-2.21-.78A16.35,16.35,0,0,0,46.62,7H40.47V17.79Z" />
    <path d="M72.06.86V30.19H89.6v6.65H64.15V.86Z" />
    <path d="M100.94.86v36H93V.86Z" />
    <path d="M104.06,7.51V.86h29.48V7.51H122.76V36.84h-7.91V7.51Z" />
    <path d="M142.71,28a5.88,5.88,0,0,0,1.74,2A7.44,7.44,0,0,0,147,31.17a12.15,12.15,0,0,0,3,.38,14.38,14.38,0,0,0,2.27-.18,7.68,7.68,0,0,0,2.26-.68,4.91,4.91,0,0,0,1.77-1.39,3.36,3.36,0,0,0,.71-2.24,3.19,3.19,0,0,0-.94-2.37,7.24,7.24,0,0,0-2.44-1.51,25.22,25.22,0,0,0-3.43-1.06c-1.28-.3-2.57-.63-3.88-1a36.53,36.53,0,0,1-3.93-1.24A13.89,13.89,0,0,1,139,18a9,9,0,0,1-2.44-2.9,8.88,8.88,0,0,1-.93-4.21,9.5,9.5,0,0,1,1.18-4.81,11,11,0,0,1,3.1-3.4,13.44,13.44,0,0,1,4.33-2A18.26,18.26,0,0,1,149.06,0a23,23,0,0,1,5.42.63,13.36,13.36,0,0,1,4.61,2,10.24,10.24,0,0,1,3.2,3.6,10.94,10.94,0,0,1,1.19,5.32h-7.67a6.2,6.2,0,0,0-.67-2.67,4.39,4.39,0,0,0-1.54-1.66,6.38,6.38,0,0,0-2.19-.86,13.5,13.5,0,0,0-2.7-.25,9.63,9.63,0,0,0-1.92.2,5.23,5.23,0,0,0-1.73.71,4.3,4.3,0,0,0-1.29,1.26,3.46,3.46,0,0,0-.5,1.91,3.18,3.18,0,0,0,.4,1.71,3.57,3.57,0,0,0,1.59,1.21,21,21,0,0,0,3.27,1.11L154,15.67c.67.14,1.61.38,2.8.73a13.63,13.63,0,0,1,3.55,1.69,10.8,10.8,0,0,1,3,3.1,8.71,8.71,0,0,1,1.28,5,10.83,10.83,0,0,1-.95,4.59,10,10,0,0,1-2.85,3.65,13.56,13.56,0,0,1-4.69,2.4,22.08,22.08,0,0,1-6.47.85,22.54,22.54,0,0,1-5.77-.73,14.55,14.55,0,0,1-4.9-2.33,11.42,11.42,0,0,1-3.4-4,11.9,11.9,0,0,1-1.21-5.75h7.66A6.91,6.91,0,0,0,142.71,28Z" />
    <path d="M176.12,7.46l-1.42,8,.1.1a9.15,9.15,0,0,1,3.13-2.09,11.68,11.68,0,0,1,8.41.33,10.14,10.14,0,0,1,3.41,2.57,11,11,0,0,1,2.11,3.8,14.19,14.19,0,0,1,.73,4.66,12,12,0,0,1-1.06,5.07,12.49,12.49,0,0,1-7,6.74,12.93,12.93,0,0,1-5,.88,17.38,17.38,0,0,1-4.91-.68,12.17,12.17,0,0,1-4.16-2.09,10.55,10.55,0,0,1-2.9-3.5,11,11,0,0,1-1.13-4.86h7.16a5.87,5.87,0,0,0,1.81,3.85,5.66,5.66,0,0,0,4,1.44,5.54,5.54,0,0,0,2.54-.58,6,6,0,0,0,1.92-1.51,6.62,6.62,0,0,0,1.15-2.2,7.63,7.63,0,0,0,.43-2.55,7.77,7.77,0,0,0-.41-2.57,5.78,5.78,0,0,0-1.21-2.11,5.35,5.35,0,0,0-1.91-1.41,6.12,6.12,0,0,0-2.57-.51,6.42,6.42,0,0,0-3.13.68A7.48,7.48,0,0,0,174,21.07H167.5L171,1.56h19.71v5.9Z" />
    <path d="M203.58,30.84a4.19,4.19,0,0,0,3.12,1.31A4.65,4.65,0,0,0,209.93,31a8.43,8.43,0,0,0,2-2.77A13.72,13.72,0,0,0,213,24.74a31.08,31.08,0,0,0,.43-3.32l-.1-.1a8.51,8.51,0,0,1-3.43,3,11.26,11.26,0,0,1-12.68-2.72,10.39,10.39,0,0,1-2.07-3.68,14.42,14.42,0,0,1-.65-4.18,14.58,14.58,0,0,1,.88-5.07A12.37,12.37,0,0,1,198,4.61a12.16,12.16,0,0,1,3.91-2.75,12.31,12.31,0,0,1,5-1,12.56,12.56,0,0,1,6.63,1.58,12.26,12.26,0,0,1,4.16,4.19,17.41,17.41,0,0,1,2.17,5.87,35.69,35.69,0,0,1,.6,6.6,28.32,28.32,0,0,1-.76,6.55,19,19,0,0,1-2.41,5.92,13.25,13.25,0,0,1-4.3,4.29,11.85,11.85,0,0,1-6.4,1.63,13.81,13.81,0,0,1-4.23-.63A10.41,10.41,0,0,1,198.89,35a10.23,10.23,0,0,1-2.47-3.07,12.22,12.22,0,0,1-1.26-4.13H202A6.1,6.1,0,0,0,203.58,30.84Zm6-11.51a5,5,0,0,0,1.76-1.44,6.26,6.26,0,0,0,1.06-2.09,8.44,8.44,0,0,0,.35-2.5,7.76,7.76,0,0,0-.38-2.39,6.52,6.52,0,0,0-1.11-2.12,5.34,5.34,0,0,0-1.79-1.48,5.15,5.15,0,0,0-2.41-.56,4.63,4.63,0,0,0-2.27.56,5.74,5.74,0,0,0-1.72,1.46,6.28,6.28,0,0,0-1.05,2.06,8.3,8.3,0,0,0-.36,2.37,9.22,9.22,0,0,0,.33,2.45,7.18,7.18,0,0,0,1,2.11,5,5,0,0,0,1.69,1.52,4.92,4.92,0,0,0,2.4.58,5.51,5.51,0,0,0,2.5-.53Z" />
    <path className="logo-la" d="M74.9,40.73V53.12h7.42v2.81H71.56V40.73Z" />
    <path
      className="logo-la"
      d="M83.61,45.23a7.61,7.61,0,0,1,1.47-2.54A6.92,6.92,0,0,1,87.44,41a8.44,8.44,0,0,1,6.29,0,6.75,6.75,0,0,1,2.34,1.71,7.29,7.29,0,0,1,1.47,2.54A9.13,9.13,0,0,1,98,48.4a9,9,0,0,1-.51,3.09A7.22,7.22,0,0,1,96.07,54a6.86,6.86,0,0,1-2.34,1.68,8.54,8.54,0,0,1-6.29,0A7,7,0,0,1,85.08,54a7.43,7.43,0,0,1-1.47-2.5,9.53,9.53,0,0,1-.51-3.09A9.8,9.8,0,0,1,83.61,45.23Zm3.06,5a5.65,5.65,0,0,0,.71,1.63A3.84,3.84,0,0,0,88.66,53a4.5,4.5,0,0,0,3.83,0,3.57,3.57,0,0,0,1.28-1.16,4.74,4.74,0,0,0,.71-1.63,7.29,7.29,0,0,0,.23-1.86,7.52,7.52,0,0,0-.23-1.94,4.85,4.85,0,0,0-.71-1.67,3.63,3.63,0,0,0-1.28-1.18,4.5,4.5,0,0,0-3.83,0,3.81,3.81,0,0,0-1.28,1.18,5.36,5.36,0,0,0-.71,1.67,8.49,8.49,0,0,0-.22,1.94,8,8,0,0,0,.22,1.89Z"
    />
    <path
      className="logo-la"
      d="M102.88,52.19a2.55,2.55,0,0,0,.74.86,3.35,3.35,0,0,0,1.07.49,5.54,5.54,0,0,0,1.28.16,6.63,6.63,0,0,0,1-.08,3.12,3.12,0,0,0,1-.29,1.92,1.92,0,0,0,.74-.58,1.43,1.43,0,0,0,.3-1,1.31,1.31,0,0,0-.39-1,3.29,3.29,0,0,0-1-.64,8.91,8.91,0,0,0-1.44-.45c-.54-.12-1.09-.27-1.64-.42a16.75,16.75,0,0,1-1.66-.52,5.57,5.57,0,0,1-1.45-.8,4.1,4.1,0,0,1-1-1.23A3.88,3.88,0,0,1,99.88,45a4.21,4.21,0,0,1,.5-2,4.71,4.71,0,0,1,1.31-1.43,6,6,0,0,1,1.83-.86,8.52,8.52,0,0,1,2-.27,10.31,10.31,0,0,1,2.29.26,5.49,5.49,0,0,1,1.94.87A4.28,4.28,0,0,1,111.16,43a4.63,4.63,0,0,1,.5,2.25h-3.24a2.38,2.38,0,0,0-.29-1.13,1.85,1.85,0,0,0-.65-.71,2.72,2.72,0,0,0-.92-.36,6.12,6.12,0,0,0-1.14-.1,3.71,3.71,0,0,0-.81.08,2.5,2.5,0,0,0-.74.3,2.09,2.09,0,0,0-.54.53,1.53,1.53,0,0,0-.21.81,1.39,1.39,0,0,0,.17.72,1.53,1.53,0,0,0,.67.51,7.75,7.75,0,0,0,1.38.47l2.31.6c.29.06.68.16,1.18.31a5.5,5.5,0,0,1,1.51.71,4.56,4.56,0,0,1,1.28,1.31,3.62,3.62,0,0,1,.55,2.1,4.48,4.48,0,0,1-.41,1.94,4.11,4.11,0,0,1-1.2,1.54,5.7,5.7,0,0,1-2,1,10.22,10.22,0,0,1-5.18,0,6.25,6.25,0,0,1-2.08-1,5,5,0,0,1-1.44-1.69,5.17,5.17,0,0,1-.51-2.42h3.24A3.1,3.1,0,0,0,102.88,52.19Z"
    />
    <path
      className="logo-la"
      d="M127.65,40.73l5.68,15.2h-3.47l-1.15-3.38H123l-1.2,3.38h-3.36l5.75-15.2Zm.19,9.33-1.92-5.58h0l-2,5.58Z"
    />
    <path className="logo-la" d="M138,40.73l6.35,10.2h0V40.73h3.13v15.2h-3.34l-6.33-10.18h0V55.93h-3.13V40.73Z" />
    <path
      className="logo-la"
      d="M159.44,55.82a5.69,5.69,0,0,1-2.2.45,7.9,7.9,0,0,1-3.14-.6A6.83,6.83,0,0,1,151.75,54a7.43,7.43,0,0,1-1.47-2.5,9.53,9.53,0,0,1-.51-3.09,9.74,9.74,0,0,1,.51-3.17,7.61,7.61,0,0,1,1.47-2.54A6.91,6.91,0,0,1,154.1,41a7.74,7.74,0,0,1,3.14-.61,7.27,7.27,0,0,1,2.27.35,6.26,6.26,0,0,1,2,1A5.59,5.59,0,0,1,163,43.42a5.5,5.5,0,0,1,.7,2.3h-3.19a3.11,3.11,0,0,0-1.15-1.91,3.33,3.33,0,0,0-2.07-.64,4,4,0,0,0-1.91.43A3.82,3.82,0,0,0,154,44.79a5.26,5.26,0,0,0-.71,1.67,8.5,8.5,0,0,0-.23,1.94,8.31,8.31,0,0,0,.23,1.86,5.15,5.15,0,0,0,.71,1.63,3.75,3.75,0,0,0,1.28,1.16,4,4,0,0,0,1.91.43,3.25,3.25,0,0,0,3.6-2.88,1.79,1.79,0,0,0,0-.4h-3.36V47.71h6.39v8.22h-2.13l-.34-1.72A5.14,5.14,0,0,1,159.44,55.82Z"
    />
    <path className="logo-la" d="M178,40.73v2.81h-8V46.8h7.37v2.59H170v3.73h8.2v2.81H166.59V40.73Z" />
    <path className="logo-la" d="M183.73,40.73V53.12h7.41v2.81H180.39V40.73Z" />
    <path className="logo-la" d="M204.38,40.73v2.81h-8V46.8h7.36v2.59h-7.36v3.73h8.19v2.81H193V40.73Z" />
    <path
      className="logo-la"
      d="M209.37,52.19a2.64,2.64,0,0,0,.73.86,3.4,3.4,0,0,0,1.08.49,5.54,5.54,0,0,0,1.28.16,6.63,6.63,0,0,0,1-.08,3.12,3.12,0,0,0,1-.29,2,2,0,0,0,.75-.58,1.44,1.44,0,0,0,.29-1,1.31,1.31,0,0,0-.39-1,3.29,3.29,0,0,0-1-.64,11.62,11.62,0,0,0-1.45-.45c-.54-.12-1.09-.27-1.64-.42a16.75,16.75,0,0,1-1.66-.52,6.43,6.43,0,0,1-1.45-.8,4,4,0,0,1-1-1.23,3.88,3.88,0,0,1-.39-1.77,4.09,4.09,0,0,1,.5-2,4.6,4.6,0,0,1,1.31-1.43,5.58,5.58,0,0,1,1.83-.86,8,8,0,0,1,2-.27,10.31,10.31,0,0,1,2.29.26,5.81,5.81,0,0,1,2,.87A4.22,4.22,0,0,1,217.64,43a4.63,4.63,0,0,1,.5,2.25H214.9a2.49,2.49,0,0,0-.28-1.13,1.85,1.85,0,0,0-.65-.71,2.58,2.58,0,0,0-.93-.36,6.12,6.12,0,0,0-1.14-.1,3.71,3.71,0,0,0-.81.08,2.56,2.56,0,0,0-.73.3,2.09,2.09,0,0,0-.54.53,1.63,1.63,0,0,0,0,1.53,1.53,1.53,0,0,0,.67.51,7.85,7.85,0,0,0,1.39.47l2.31.6c.28.06.68.16,1.18.31a6.25,6.25,0,0,1,1.5.71,4.47,4.47,0,0,1,1.29,1.31,3.69,3.69,0,0,1,.54,2.1,4.62,4.62,0,0,1-.4,1.94A4.1,4.1,0,0,1,217,54.9a5.7,5.7,0,0,1-2,1,10.18,10.18,0,0,1-5.17.05,6.3,6.3,0,0,1-2.09-1,4.77,4.77,0,0,1-1.43-1.69,4.94,4.94,0,0,1-.51-2.42H209A2.87,2.87,0,0,0,209.37,52.19Z"
    />
  </svg>
)
export default Logo
