import React, { useEffect, useState } from 'react'
import { mq, theme } from '@theme'
import styled from '@emotion/styled'
import fetchShippingProgress from '@/data/contentful/fetchShippingProgress'

const ShippingProgressTracker = ({ isInMiniCart = true, totalPricePurchases }) => {
    const [shippingProgressLimit, setShippingProgressLimit] = useState(null);
    const [shippingProgressInfo, setShippingProgressInfo] = useState();

    const progressPercentage = (totalPricePurchases / shippingProgressLimit) * 100;
    const remainingPurchasesForShipping = shippingProgressLimit - totalPricePurchases;

    useEffect(async () => {
        try {
        const shippingProgress = await fetchShippingProgress();
        setShippingProgressInfo(shippingProgress)
        console.log('shipping:', shippingProgress)
        if (shippingProgress && shippingProgress.enableShippingProgress) {
            setShippingProgressLimit(shippingProgress.eligibilityOnEstimatedTotal);
        }
        } catch (error) {
        console.error("Error fetching shippig",error)
        }

    }, [])

    
    const ShippingBarText = styled.div`
        ${theme.font.caps06}
        text-align: center;
        font-weight: 300;
        margin-bottom: 6px;

        b {
            font-weight: 500 !important;
        }

        ${mq['sm']} {
            ${isInMiniCart ? theme.font.caps06 : theme.font.caps09}
            font-weight: ${isInMiniCart && 300};
            text-align: ${isInMiniCart ? 'center' : 'left'};
        }
    `
    const BackgroundBar = styled.div`
        position: relative;
        height: 3px;
        width: 100%;
        background-color: #d9d9d9;
        margin-bottom: 24px;
        
        ${mq['sm']} {
            height: ${isInMiniCart ? "3px" : "5px"};
        }
    `
    const ProgressBar = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        width: ${props => props.width}%;
        max-width: 100%;
        height: inherit;
    `

    return (
        shippingProgressLimit && (
            <>
                {
                    totalPricePurchases < shippingProgressLimit ? (
                        <ShippingBarText> {shippingProgressInfo?.progressMessageBeforePrice} <b>${remainingPurchasesForShipping.toFixed(2)}</b> {shippingProgressInfo?.progressMessageAfterPrice}</ShippingBarText>
                    ) : (
                        <ShippingBarText>{shippingProgressInfo?.successProgressMessage}</ShippingBarText>
                    )
                }
                
                <BackgroundBar>
                    <ProgressBar width={progressPercentage}></ProgressBar>
                </BackgroundBar>
            </>
        )
    )
}

export default ShippingProgressTracker
