import { useState, createContext, useContext } from 'react'

const CartContext = createContext()

export const CartContextProvider = ({ children }) => {
  const [cartId, setCartId] = useState(null)
  const [cartToggled, setCartToggled] = useState(false)
  const [checkoutUrl, setCheckoutUrl] = useState(null)
  const [productmini, setProductMini] = useState(null)
  const [productCount, setProductCount] = useState(null)
  const [tryNowCount, setTryNowCount] = useState(0)
  const [simpleproductcount, setSimpleProductCount] = useState(false)
  const [disableQuantityy, setDisableQuantity] = useState(false)
  const [preorderproducts, setPreOrderProducts] = useState('')
  const [initialQuantities, setInitialQuantities] = useState({})

  return (
    <CartContext.Provider
      value={{
        cartId,
        setCartId,
        cartToggled,
        setCartToggled,
        checkoutUrl,
        preorderproducts,
        setPreOrderProducts,
        setCheckoutUrl,
        productmini,
        setProductMini,
        productCount,
        setProductCount,
        tryNowCount,
        initialQuantities,
        setInitialQuantities,
        setTryNowCount,
        simpleproductcount,
        setSimpleProductCount,
        disableQuantityy,
        setDisableQuantity
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCartContext = () => {
  return useContext(CartContext)
}
