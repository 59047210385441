import React, { createContext, useState, useEffect } from "react";

const CustomerContext = createContext(null);

function CustomerProvider({ children, hotData }) {

  const [data, setData] = useState(null);

  const setDataCallback = React.useCallback(args => setData(args), []);

  useEffect(() => {
    setDataCallback(hotData);
  }, [hotData, setDataCallback]);

  return <CustomerContext.Provider value={{ data, setData: setDataCallback }}>{children}</CustomerContext.Provider>
}

function useCustomer() {
  const context = React.useContext(CustomerContext);
  return React.useMemo(() => ({
    set: args => context.setData(args),
    ...context.data
  }), [context]);
}


export { CustomerProvider, useCustomer }