import React from 'react'

import styled from '@emotion/styled'
import { theme } from '@theme'



const VARIANTS = {
  portrait: 0.658,
  product: 0.658,
  square: 1,
  landscape: 1.4,
  panoramic: 2.5,
  customSetTop: 0.777,
  customSetBottom: 0.4942
}

const Root = styled.div`
  position: relative;

  background: ${(props) => props.backgroundColor ?? theme.colors.mono50};
  ${(props) => (props.ratio ? `padding-bottom: ${props.ratio * 100}%;` : '')}
  }
`

const VideoWrap = styled.div`
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;
`
const VideoElement = styled.video`
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
`

const CardVideo = ({ ...props }) => {
    let _ratio = VARIANTS.product

  return (
    <Root
      ratio={1 / _ratio}
    >
        <VideoWrap>
              <VideoElement autoPlay loop>
                <source src={props.featuredVideo} />
              </VideoElement>
        </VideoWrap>
    </Root>
  )
}

export default CardVideo
