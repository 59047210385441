const formatPrice = (price, currencySymbol, currencyCode = '') => {
  // let _amount = typeof price.amount === 'number' ? price.amount : parseFloat(price.amount) // assume that _amount is a number
  let _amount =
    typeof price === 'number' ? price : typeof price.amount === 'number' ? price.amount : parseFloat(price.amount) //for bundle Prices on PDP

  _amount = (Math.ceil(_amount * 100) / 100).toFixed(2).toString().replace('.00', '')
  // format price , after the last 3 digits add a comma
  if (_amount.length > 3) _amount = _amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // return `$${_amount}`
  return `${currencySymbol}${_amount}${' ' + currencyCode}`
}

export default formatPrice
