import React from 'react'

import { mq, theme } from '@theme'
import styled from '@emotion/styled'

import NextLink from 'next/link'

const TnCartWidgetText = styled.div`
  ${theme.font.body02}
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`

const KeepShopping = styled.a`
  ${theme.font.body02}
  text-decoration: underline;
  font-weight: 500;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`

const TryNowCartWidget = ({ itemsLeft }) => {
  return (
    <TnCartWidgetText>
      YOU CAN ADD {itemsLeft} MORE ITEMS TO YOUR TRYNOW ORDER.&nbsp;
      <NextLink href={'/'} passHref>
        <KeepShopping aria-label="keep shopping">KEEP SHOPPING?</KeepShopping>
      </NextLink>
    </TnCartWidgetText>
  )
}

export default TryNowCartWidget
