// Main Country Selector Component

import { useEffect } from 'react'
import Modal from 'react-modal'
import { Global, css } from '@emotion/react'
import ReactCountryFlag from 'react-country-flag'
import styled from '@emotion/styled'
import { theme } from '@theme'
import CloseButton, { Arrow } from './close'
import { useGlobalE } from '@/hooks/Global-E'

const CountryModal = ({ isOpen, onRequestClose, closeTimeoutMS, children }) => {
  return (
    <>
      <Global
        styles={css`
          .modal-overlay1 {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: none;
            background: none;
            padding: 0;
            overflow: visible;
            width: 100%;
            height: 100%;
            display: grid;

            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.5);
          }
          .modal-overlay-after-open {
            background: rgba(0, 0, 0, 0.5);
          }
          .modal-overlay-before-close {
            background: rgba(0, 0, 0, 0);
          }
          .modal-content1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8rem;
            outline: none;
            border: none;
            background: none;
          }

          .main-child {
            max-width: 450px;
            min-height: 325px;
          }

          @media (max-width: 768px) {
            .main-child {
              max-width: 90%;
              margin: 0;
            }
            .modal-content1 {
              margin-bottom: 4rem;
            }
          }
        `}
      />

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        closeTimeoutMS={closeTimeoutMS}
        ariaHideApp={false}
        overlayClassName={{
          base: 'modal-overlay1'
        }}
        className={{
          base: 'modal-content1'
        }}
      >
        <div className="main-child">{children}</div>
      </Modal>
    </>
  )
}

const Root = styled.div`
  position: relative;
  background-color: white;
  padding: 28px;
  display: flex;
  flex-direction: column;

  .img-wrap {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  h1 {
    margin-bottom: 36px;
    width: 100%;
    text-align: center;
    ${theme.font.caps05}
    strong {
      font-weight: 600;
    }
  }
  h2 {
    margin-bottom: 9px;
    ${theme.font.caps07}
  }
  p {
    margin-bottom: 22px;
    ${theme.font.body02}
  }
  span {
    margin-bottom: 13px;
    ${theme.font.body02}
    color: #C6C6C6 !important;
  }

  .btn {
    background: #000;
    padding: 20px 121px;
    color: #fff;
    ${theme.font.caps07}
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 34px;
    margin-bottom: 14px;
  }

  select {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    outline: none;

    // on ios show same text color
    // -webkit-text-fill-color: black;
    // compactable with all browser
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    appearance: none;
    -webkit-box-shadow: none;
    -webkit-border-radius: 0;

    color: black !important;
    background: transparent !important;
    ${theme.font.body02}

    option {
      color: black !important;
      ${theme.font.body02}
    }
  }

  .arrow {
    position: absolute;
    right: 8px;
    top: -2px;
  }

  .close-btn {
    position: absolute;
    top: 23px;
    right: 23px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .btn {
      padding: 20px 50px;
    }
  }
`
const CountrySelectorModal = () => {
  const { data, setData } = useGlobalE()

  useEffect(() => {
    if (data.shownPopup) {
      const body = document.querySelector('body')
      body.style.overflow = 'hidden'
    } else {
      const body = document.querySelector('body')
      body.style.overflow = 'auto'
    }

    return () => {
      const body = document.querySelector('body')
      body.style.overflow = 'auto'
    }
  }, [data.shownPopup])

  const onSelect = (e) => {
    let country = data.countriesList.find((country) => country.label === e.target.value)
    setData({
      ...data,
      country: country
    })
    localStorage.setItem('selectedCountry', JSON.stringify(country))
  }

  const close = () => {
    setData({
      ...data,
      shownPopup: false
    })

    if (window.location.pathname === '/') {
      window.location.reload()
    }
  }

  if (!data.shownPopup) {
    return null
  }

  return (
    <>
      <CountryModal isOpen={data.shownPopup} onRequestClose={close} closeTimeoutMS={100}>
        <Root>
          <CloseButton onClick={close} />
          <div className="img-wrap">
            <ReactCountryFlag
              countryCode={data.country?.value}
              svg
              style={{
                width: '90px',
                height: '46px'
              }}
              title={data.country?.label}
            />
          </div>

          <h1>
            Currently Shipping to <strong>{data.country?.label}</strong>
          </h1>

          <h2>Shipping to a different location?</h2>
          <p>
            To ship your items to a different location, please select from the list below. Item availability, prices and
            delivery information will be updated in line with your new shipping destination.
          </p>

          <span>Choose a new location</span>
          <div
            style={{
              position: 'relative'
            }}
          >
            <select value={data.country.label} onChange={(e) => onSelect(e)}>
              {data.countriesList.map((country, index) => (
                <option key={index + 1} value={country.label} id={JSON.stringify(country)}>
                  {country.label}
                </option>
              ))}
            </select>
            <Arrow />
          </div>
          <button onClick={close} className="btn">
            update shipping location
          </button>
        </Root>
      </CountryModal>
    </>
  )
}

export default CountrySelectorModal
