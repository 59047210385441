import { graphqlQuery } from '@/data/shopify/services/shopifyApolloClient'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import gql from 'graphql-tag'

const initialState = {
  loading: false,
  error: null,
  shownPopup: false,
  countriestList: [],
  country: {
    label: null,
    value: null
  },
  currency: {
    isoCode: 'USD',
    name: 'US Dollar',
    symbol: null
  }
}

// get countries list from Shopify
async function getCountries() {
  const { data } = await graphqlQuery({
    query: gql`
      query @inContext(country: FR) {
        localization {
          availableCountries {
            isoCode
            name
          }
        }
      }
    `
  })

  return data
}

// get testing Costo Rico get currency
async function getCurrency(countryCode) {
  if (!countryCode) {
    return null
  }
  const { data } = await graphqlQuery({
    query: gql`
        query @inContext(country: ${countryCode}) {
          localization {
            country {
              currency {
                isoCode
                name
                symbol
              }
              isoCode
              name
            }
          }
        }
      `
  })

  return data
}

const fetchCountry = async () => {
  const res = await fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
  const data = await res.json()

  const modifyLabel = data.userSelectValue.label.split(' ').slice(1).join(' ')
  return { ...data.userSelectValue, label: modifyLabel }
}

const fetchCountriesList = async () => {
  const countries = await getCountries()
  const modifyData = countries.localization.availableCountries.map((country) => {
    return {
      // ...country,
      label: country.name,
      value: country.isoCode
    }
  })
  return modifyData
}

// call on load
const getCountryList = createAsyncThunk('globalE/getCountryList', async () => {
  const countries = await fetchCountriesList()
  return countries
})

// call on change country
export const getCountry = createAsyncThunk('globalE/getCountry', async (countryCode) => {
  const country = await getCurrency(countryCode)
  return country
})

// get country
export const getCountryFromIp = createAsyncThunk('globalE/getCountryFromIp', async () => {
  const country = await fetchCountry()
  return country
})

const globalSlice = createSlice({
  name: 'globalE',
  initialState,
  reducers: {
    setCountry(state, action) {
      state.country = action.payload
    },
    setCountryList(state, action) {
      state.countriestList = action.payload
    },
    setCurrency(state, action) {
      state.currency = action.payload
    },
    setShownPopup(state, action) {
      state.shownPopup = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.loading = false
        state.countriestList = action.payload
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getCountry.pending, (state) => {
        state.loading = true
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        state.loading = false
        state.currency = action.payload
      })
      .addCase(getCountry.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getCountryFromIp.pending, (state) => {
        state.loading = true
      })
      .addCase(getCountryFromIp.fulfilled, (state, action) => {
        state.loading = false
        state.country = action.payload
      })
      .addCase(getCountryFromIp.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
});


export { getCountryList }
export const { setCountry, setCountryList, setCurrency, setShownPopup } = globalSlice.actions
export default globalSlice.reducer
