import React from 'react'

import { mq, theme } from '@theme'
import styled from '@emotion/styled'

const TryNowCartDisclaimer = ({ marginTop }) => {

    const TnCartDisclaimer = styled.div`
        ${theme.font.body02}
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: normal;
        text-transform: uppercase;
        margin-top: -2rem;
    `

    const tnURL = "https://cdn.shopify.com/s/files/1/1148/2032/files/TryNowlogo_Black.png?v=1715773779";
    let imgStyle = {
        height: 20,
        width: 60,
        "objectFit": "cover",
        "objectPosition": "left",
        "marginRight": 5
    }

    return (
        <TnCartDisclaimer>
          <img style={imgStyle} src={tnURL}></img>
          FREE FOR 5 DAYS. ONLY PAY FOR WHAT YOU KEEP.
        </TnCartDisclaimer> 
    )
}

export default TryNowCartDisclaimer
