import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import Link from 'next/link'
import axios from 'axios'
import { FitLoader } from '../PageLoader'
import { useCartContext } from '@/contexts/CartContext'
import { displayCart } from '@/utils/displayCart'
import { createCart } from '@/utils/createCart'
import { removeFromCart } from '@/utils/removeFromCart'
import { usePurpleDotCheckout } from '@purple-dot/purple-dot-react'
import formatPrice from '@/helpers/formatPrice'
import styled from 'styled-components'
import { theme } from '../theme'
import { ButtonPrimary, ButtonSecondary } from '../Button'
import { storefront } from '@/utils'
import useShowUpSell from '@/hooks/ShowUpSell'
import { useGlobalE } from '@/hooks/Global-E'
import UpsellSlider from '../UpsellSlider'
import IconClose from '../_icons/IconClose'
import { useRouter } from 'next/router'
import TryNowCartWidget from '../TryNow/CartWidget'
import ShippingProgressTracker from '../ShippingProgressTracker'
import TryNowCartDisclaimer from '../TryNow/CartDisclaimer'
import { useAnalytics } from '@/hooks/Analytics'

const CartContainer = styled.aside`
  ${theme.font.caps07}
  background-color: white;
  color: #212529;
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 3em;
  z-index: 1000;
  padding: 20px 20px;
  margin: 0 0 0 auto;
  max-width: 450px;
  cursor: default;
  overflow: hidden !important;
  height: 100%;

  @media (max-width: 1300px) {
    padding: 20px 20px;
    margin: 0 0 0 auto;
    max-width: 450px;
  }
  @media (max-width: 800px) {
    padding: 37px -1px; //was 9 at last
    margin: 0 0 0 auto;
    max-width: 450px;
    overflow-y: auto;
    height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 500px) {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    // height: 100vh;
    width: 100%;
    // max-width: 350px;
  }
`

const CartHeading = styled.h2`
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
  color: #343a40;
`

const CartSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 100%;
  position: relative;
`

const CartProducts = styled.div`
  // height: 70vh;
  height: calc(100vh - 105px - 155px);
  overflow-y: scroll;
  flex-grow: 1;
  overflow-x: hidden;
  padding: 0 0px;
  margin-top: -7rem;
  // padding-left: 20px;
  // padding-right: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.selectedCountry !== 'US' &&
    `
     height: calc(100vh - 105px - 155px);

 `};
  ${(props) =>
    props.tryNowCount &&
    props.selectedCountry !== 'US' &&
    `
 height: calc(100vh - 105px - 125px);
`};

  @media (max-width: 500px) {
    // height: 66vh;
    height: calc(100vh - 105px - 160px);

    // margin-top: auto;
    ${(props) =>
      props.isBundle &&
      `
  // margin-top: -5rem;
    
   `}
  }
  // @media (max-width: 376px) {
  // padding-left: 10px;
  padding-right: 5px;
  // }

  scrollbar-width: none;
  -ms-overflow-style: none;
`

const Product = styled.article`
  display: flex;
  align-items: stretch;
  // margin: 20px 0;
  // border-bottom: 1px solid #dee2e6;
  padding-bottom: 16px;
  margin-top: 5px !important;
  ${(props) =>
    props.isBundle &&
    `
    position: relative;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: none !important;
    margin: 0px 0px !important;
    // padding-top: 10px !important;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -30px; 
      height: 105%; 
      width: 2px;
      background-color: black;
    }

 `}
  @media (max-width: 500px) {
    margin: 20px 0;
    padding-bottom: 3px;
    margin-top: 20px !important;

    ${(props) =>
      props.isBundle &&
      `
      margin-bottom: 20px !important;
      padding-bottom: 0px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -39px; 
        height: 125%; 
        width: 2px;
        background-color: black;
      }
  
   `}// padding-right: 8px;
    // padding-left: 8px;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
  }
  @media (max-width: 375px) {
    // padding-right: 8px;
    // padding-left: 8px;
  }
`
const BundleIMage = styled.img`
  width: 100px;
  height: 150px;
  cursor: pointer;
`
const ImageSimple = styled.img`
  cursor: pointer;
  width: 100px;
  height: 150px;
  ${(props) =>
    props.tryNowCount &&
    `
   height: 150px
 `}
`

const ProductDetails = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  // min-width: 280px;
  @media (max-width: 500px) {
    // min-width: 224px;
  }
`
const StyledProduct = styled.div`
  ${(props) =>
    props.isBundle &&
    `
    border-left: 3px solid black;
    padding-left: 10px;
 `}
`

const TitleAndPriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 500px) {
    // width: 95%;
  }
`

const ProductName = styled.h3`
  font-size: 1.1em;
  font-weight: 500;
  cursor: pointer;
  margin-right: 7px;
  // color: #495057;
`

const ProductVariant = styled.p`
  margin-top: 1rem;
  font-size: 1em;
  font-weight: 100;
  // color: #6c757d;
`
const ProductVariantSecond = styled.p`
  margin-top: 0.5rem;
  font-size: 1em;
  font-weight: 100;
  // color: #6c757d;
`
const LengthVariant = styled.p`
  margin-top: 0.5rem;
  font-size: 1em;
  font-weight: 100;
  // color: #6c757d;
`

const ProductSubContainer = styled.div`
  align-items: baseline !important;
  display: flex !important;
  // justify-content: space-between;
  width: 100%;
  flex-direction: row;
`

const ProductQuantity = styled.p`
  font-size: 1em;
  color: #495057;
`
const DeleteItem = styled.p`
  // ${theme.font.caps09}
  ${(props) =>
    props.finalsale &&
    `
    margin-top: 0.5rem !important;
 `};
  margin-top: 1rem;
  color: black;
  font-weight: 200;
  text-decoration: underline;
`
const DeleteItemBundle = styled.p`
  // ${theme.font.caps09}
  // margin-top: 1rem;
  color: black;
  font-weight: 200;
  text-decoration: underline;
`
const FInalSaleItem = styled.p`
  // ${theme.font.caps09}
  padding-top: 10px;
  color: gray;
  font-size: 16px !important;
  font-weight: 100;
  // text-decoration: underline;
`
const TryNowItem = styled.p`
  // ${theme.font.caps09}
  margin-top: 0.2rem;
  color: black;
  padding-top: 7px;
  padding-bottom: 5px;
  font-size: calc(9.53742px + 0.340136vw);
  font-weight: 400;
  // text-decoration: underline;
`
const OneLeftItem = styled.p`
  // ${theme.font.caps09}
  margin-top: 0.2rem;
  color: red;
  font-weight: 300;
  // text-decoration: underline;
`
const MorLeftItem = styled.p`
  // ${theme.font.caps09}
  margin-top: 0.5rem;
  padding-left: 10px;
  color: red;
  font-size: 16px !important;
  font-weight: 300;
  // text-decoration: underline;
`

const ProductCost = styled.p`
  font-size: 1em;
  color: black;
  white-space: nowrap;
`

const ProductCostCompareAT = styled.p`
  font-size: 1em;
  color: rgb(170, 170, 170);
  position: relative;
  // padding: 10px 0;
  margin-left: auto;
  margin-right: 5px;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(170, 170, 170);
  }
`

const RemoveBtn = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
  // margin-left: -5px;
  padding: 0;
  color: #dc3545;
  ${(props) =>
    props.giftCard &&
    `
   margin-top: 2.5rem;
 `};
  &:hover {
    color: #c82333;
  }

  @media (max-width: 500px) {
    margin-left: 0px;
  }
`

const StaticContainer = styled.section`
  bottom: 0;
  position: relative;
  width: 410px;
  ${(props) =>
    props.tryNowCount === 0 &&
    `
    margin-bottom: -1.5rem;
 `};
  ${(props) =>
    props.selectedCountry !== 'US' &&
    `
    margin-bottom: 0.5rem;
 `};
  z-index: 9999;
  // padding-top: 9px;
  // margin-top: 7.5rem;
  // padding: 1em 0;
  background-color: #fff;
  ${(props) =>
    props.tryNowCount &&
    `
    margin-top: -2.5rem;
 `};

  @media (max-width: 500px) {
    position: fixed;
    padding-right: 20px !important;
    // padding-left: 20px !important;
    margin-top: -4.5rem;
    // padding-top: 15px;
    ${(props) =>
      props.tryNowCount &&
      `
      margin-top: -9rem;
   `}
  }
  @media (max-width: 415px) {
    padding-right: 33px !important;
    // padding-left: 20px !important;
    margin-top: -4.5rem;
    // padding-top: 15px;
    ${(props) =>
      props.tryNowCount &&
      `
      margin-top: -9rem;
   `}
  }
  @media (max-width: 391px) {
    padding-right: 60px !important;
    // padding-left: 20px !important;
    margin-top: -4.5rem;
    ${(props) =>
      props.tryNowCount &&
      `
      margin-top: -9rem;
   `}
  }
  @media (max-width: 376px) {
    padding-right: 73px !important;
    // padding-left: 20px !important;
    margin-top: -4.5rem;
    ${(props) =>
      props.tryNowCount &&
      `
      margin-top: -9rem;
   `}
  }
  @media (max-width: 361px) {
    padding-right: 89px !important;
    // padding-left: 20px !important;
    margin-top: -4.5rem;
    ${(props) =>
      props.tryNowCount &&
      `
      margin-top: -9rem;
   `}
  }
`

const ExtraDiv = styled.div`
  margin-bottom: 0;
  @media (max-width: 500px) {
    margin-top: 0.5rem;
  }
`
const TotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: 'DINCondensed', sans-serif;
  font-weight: 400;
  // z-index: 1001;
  font-style: normal;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  ${(props) =>
    props.tryNowCount &&
    `
   padding-top: 13px;
   padding-bottom: 2px;
`};
`

const Subtotal = styled.p`
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  color: #6c757d;
`

const GrandTotal = styled.p`
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  color: #343a40;
`

const CartBtn = styled.button`
  display: block;
  width: 100%;
  margin: 20px 0;
  text-transform: uppercase;
  margin-top: 5px;
  font-size: 1em;
  padding: 10px 0;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #e9ecef;
  }
`

const CheckoutBtn = styled(CartBtn)`
  ${theme.font.caps07}
  background-color: black;
  color: white;
  appearance: none;
  margin-top:10px !important;
  margin-bottom:10px !important;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    padding: 0px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    background: black;
    color: white;
    height: 60px;
    font-family: DINCondensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: -0.01em;
}
  &:hover {
    background-color: gray;
  }
`

const EmptyBtn = styled(CartBtn)`
  ${theme.font.caps07}
  background-color: black;
  color: #fff;
  &:hover {
    background-color: gray;
  }
`

const ProductGrid = styled.div`
  display: grid;
  ${theme.size.s('grid-gap')}
`

const CartMessage = styled.p`
  text-align: center;
  margin-top: 2em;
  color: #6c757d;
  @media (max-width: 500px) {
    margin-top: 6em;
  }
`

const IncrementButton = styled.button`
  background-color: white;
  color: black;
  border: none;
  // padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  border-radius: 1px;
  // margin-left: 1rem;
  // &:hover {
  //   background-color: black;
  //   color: white;
  // }
`

const DecrementButton = styled.button`
  background-color: white;
  color: black;
  border: none;
  // padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 1px;
  // margin-left: -3px;
  padding: 0;

  // &:hover {
  //   background-color: black;
  //   color: white;
  // }
`

const QuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  // gap: 5px;
  width: 50px;
  @media (max-width: 500px) {
    margin-left: 0px !important;
    // gap: 5px;
    align-items: baseline !important;
  }
`

const UpsellWrap = styled.div`
  position: relative;
  margin-top: 15px;
  margin-bottom: 11rem;
  overflow: auto;
  z-index: 0;
  ${(props) =>
    props.tryNowCount &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 1;
   }
 `};

  ${(props) =>
    props.isCartEmpty &&
    `
    margin-top: 4rem !important;
 }
`};

  @media (max-width: 500px) {
    margin-bottom: 7rem;
    ${(props) =>
      props.tryNowCount &&
      `
   overflow: hidden;
 `};

    ${(props) =>
      props.isCartEmpty &&
      `
  margin-top: 3rem !important;
}
`};
  }
`
const UpsellTitle = styled.div`
  ${theme.font.caps07}
`

const BannerDiv = styled.div`
  ${theme.font.caps06}
  font-weight: 300;
  text-align: center;
  // margin-top: 0rem;
  // border-bottom: 4px solid black;
  width: 100%;
  // padding-bottom: 5px;
  @media (max-width: 500px) {
    margin-top: 1rem;
    display: block;
  }
`

const CatchDiv = styled.div`
  margin-top: 1%;
  // margin-left: 3.5rem;
  // width: 2vw;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.selectedCountry !== 'US' &&
    `
    display: none
 `}

  @media(max-width: 500px) {
    margin-left: 3.8rem !important;
    margin-bottom: 2rem;
  }
`

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
  gap: 40px;
  @media (max-width: 500px) {
    gap: 3px !important;
  }
`

const BagText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // height: 9vh;

  @media (max-width: 500px) {
    width: 104%;
    margin-right: -0.5rem;
    // height: 11%;
    margin-bottom: 30px;
  }
`
const TextWrap = styled.span`
  ${theme.size.menuBarHeight('height')};
  ${theme.font.caps06};
  border: none;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5em;
  align-items: top;
`
const CloseButton = styled.button`
  ${theme.size.menuBarHeight('height')};
  ${theme.font.caps07};
  background-color: white;
  cursor: pointer;
  border: none;
  color: black;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5em;
  align-items: top;
`

export default function Cart() {
  const [products, setProducts] = useState([])
  const [cost, setCost] = useState({})
  const [url, setUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false)
  const [bundles, setBundles] = useState({})
  const [matchingProducts, setMatchingProducts] = useState(false)
  const [isOnSale, setIsOnSale] = useState({})
  const [customsetsname, setCustomSetsName] = useState('')
  // const [initialQuantities, setInitialQuantities] = useState({})
  // const [quantity, setQuantity] = useState({})
  const [tags, setTags] = useState('')
  const [itemCount, setItemCount] = useState(0)
  const initialSaleStatusRef = useRef({})
  const [productsWithBundleDiscount, setProductsWithBundleDiscount] = useState([])
  const [isCountryUpdated, setIsCountryUpdated] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [disabledBundles, setDisabledBundles] = useState({})
  const [productdetailsprice, setProductDetaills] = useState(0)
  const [currencyval, setCurrencyValue] = useState('')
  const [newmaxval, setMaxValue] = useState(0)
  const [selectedCountryyy, setSelectedCountry] = useState('')
  const [newbit, setBit] = useState(false)
  const [countryDataa, setCountryDataa] = useState(() => {
    const storedCountryData = window.localStorage.getItem('selectedCountry')
    return storedCountryData ? JSON.parse(storedCountryData) : null
  })

  const Analytics = useAnalytics()

  const {
    cartId,
    setCartId,
    cartToggled,
    setCartToggled,
    setProductMini,
    productmini,
    setProductCount,
    productCount,
    setTryNowCount,
    tryNowCount,
    setSimpleProductCount,
    simpleproductcount,
    setDisableQuantity,
    disableQuantityy,
    preorderproducts,
    setPreOrderProducts,
    setInitialQuantities,
    initialQuantities
  } = useCartContext()
  const { data } = useGlobalE()
  const router = useRouter()
  const { country } = router.query

  const { upSell, loading: loadingUpSell, upsellProducts } = useShowUpSell(data.country?.value)
  const discountedProducts = products?.filter(
    (product) => product.node.attributes && product.node.attributes._set_discount
  )

  const producthandle = productmini?.handle
  const productcarthandle = products?.map((val) => val.node?.merchandise?.product.handle)

  const handle = products?.map((val) => val?.node.merchandise.product.handle)

  useEffect(() => {
    if (productmini && productmini?.tags) {
      setTags(productmini?.tags)
    }
  }, [productmini])
  useEffect(() => {
    if (products) {
      setPreOrderProducts(products)
    }
  }, [products])
  // console.log('productmini: ', products)

  const shopifyCart = {
    items:
      products && products
        ? products?.map((val) => {
            const properties = {}
            if (val.node.attributes) {
              val.node.attributes.forEach(({ key, value }) => {
                properties[key] = value
              })
            }
            return {
              id: val.node.merchandise.id,
              quantity: val.node.quantity,
              properties
            }
          })
        : []
  }

  const { preorderCheckout } = usePurpleDotCheckout({ cart: shopifyCart })
  // console.log('preorderCheckout: ', preorderCheckout)
  // console.log('shopifyCart: ', shopifyCart)

  const countryCurrencyMap = {
    PK: { priceMultiplier: parseFloat('286.96'), currency: '₨' },
    DE: { priceMultiplier: parseFloat('1.22'), currency: '€' },
    SA: { priceMultiplier: parseFloat('3.89'), currency: '﷼' },
    AT: { currency: '€' },
    US: { currency: '$' },
    AI: { currency: '$' },
    CL: { currency: '$' },
    GB: { priceMultiplier: parseFloat('0.75'), currency: '£' },
    AU: { priceMultiplier: parseFloat('1.50'), currency: 'A$' },
    CA: { priceMultiplier: parseFloat('1.30'), currency: 'C$' },
    FR: { priceMultiplier: parseFloat('0.85'), currency: 'EUR' },
    IT: { priceMultiplier: parseFloat('0.85'), currency: 'EUR' },
    ES: { priceMultiplier: parseFloat('0.85'), currency: 'EUR' },
    JP: { priceMultiplier: parseFloat('157.77'), currency: '¥' },
    CN: { priceMultiplier: parseFloat('6.80'), currency: '¥' },
    IN: { priceMultiplier: parseFloat('86.2'), currency: '₹' },
    RU: { priceMultiplier: parseFloat('7.50'), currency: '₽' },
    BR: { priceMultiplier: parseFloat('5.00'), currency: 'R$' },
    MX: { priceMultiplier: parseFloat('20.00'), currency: 'MXN' },
    AR: { priceMultiplier: parseFloat('100.00'), currency: 'ARS' },
    ZA: { priceMultiplier: parseFloat('15.00'), currency: 'ZAR' },
    NZ: { priceMultiplier: parseFloat('1.50'), currency: 'NZ$' },
    AE: { priceMultiplier: parseFloat('3.67'), currency: 'AED' },
    SG: { priceMultiplier: parseFloat('1.38'), currency: 'SGD' },
    MY: { priceMultiplier: parseFloat('4.20'), currency: 'MYR' },
    TH: { priceMultiplier: parseFloat('35.00'), currency: 'THB' },
    VN: { priceMultiplier: parseFloat('23000.00'), currency: 'VND' },
    ID: { priceMultiplier: parseFloat('14000.00'), currency: 'IDR' },
    KR: { priceMultiplier: parseFloat('1200.00'), currency: 'KRW' },
    PH: { priceMultiplier: parseFloat('50.00'), currency: 'PHP' },
    HK: { priceMultiplier: parseFloat('8.00'), currency: 'HKD' },
    TW: { priceMultiplier: parseFloat('33.34'), currency: 'TWD' },
    CH: { priceMultiplier: parseFloat('1.05'), currency: 'CHF' },
    SE: { priceMultiplier: parseFloat('9.00'), currency: 'SEK' },
    NO: { priceMultiplier: parseFloat('8.50'), currency: 'NOK' },
    DK: { priceMultiplier: parseFloat('6.50'), currency: 'DKK' },
    FI: { priceMultiplier: parseFloat('1.10'), currency: 'EUR' },
    PL: { priceMultiplier: parseFloat('4.20'), currency: 'PLN' },
    CZ: { priceMultiplier: parseFloat('25.00'), currency: 'CZK' },
    HU: { priceMultiplier: parseFloat('300.00'), currency: 'HUF' },
    RO: { priceMultiplier: parseFloat('4.50'), currency: 'RON' },
    BG: { priceMultiplier: parseFloat('1.95'), currency: 'BGN' },
    LT: { priceMultiplier: parseFloat('3.00'), currency: 'EUR' },
    LV: { priceMultiplier: parseFloat('0.63'), currency: 'EUR' },
    EE: { priceMultiplier: parseFloat('1.40'), currency: 'EUR' },
    IS: { priceMultiplier: parseFloat('120.00'), currency: 'ISK' },
    LI: { priceMultiplier: parseFloat('1.07'), currency: 'CHF' },
    SI: { priceMultiplier: parseFloat('0.85'), currency: 'EUR' },
    CY: { priceMultiplier: parseFloat('0.58'), currency: 'EUR' },
    MT: { priceMultiplier: parseFloat('0.43'), currency: 'EUR' },
    GR: { priceMultiplier: parseFloat('0.85'), currency: 'EUR' },
    AL: { priceMultiplier: parseFloat('100.00'), currency: 'L' },
    BA: { priceMultiplier: parseFloat('1.95'), currency: 'BAM' },
    HR: { priceMultiplier: parseFloat('7.53'), currency: 'HRK' },
    MK: { priceMultiplier: parseFloat('56.00'), currency: 'MKD' },
    ME: { priceMultiplier: parseFloat('1.90'), currency: 'EUR' },
    RS: { priceMultiplier: parseFloat('100.00'), currency: 'RSD' },
    TR: { priceMultiplier: parseFloat('8.50'), currency: 'TRY' },
    UA: { priceMultiplier: parseFloat('27.00'), currency: 'UAH' },
    BY: { priceMultiplier: parseFloat('2.50'), currency: 'BYN' },
    MD: { priceMultiplier: parseFloat('19.00'), currency: 'MDL' },
    AM: { priceMultiplier: parseFloat('480.00'), currency: 'AMD' },
    AZ: { priceMultiplier: parseFloat('1.70'), currency: 'AZN' },
    GE: { priceMultiplier: parseFloat('2.95'), currency: 'GEL' },
    KZ: { priceMultiplier: parseFloat('400.00'), currency: 'KZT' },
    KG: { priceMultiplier: parseFloat('80.00'), currency: 'KGS' },
    TJ: { priceMultiplier: parseFloat('11.50'), currency: 'TJS' },
    UZ: { priceMultiplier: parseFloat('1000.00'), currency: 'UZS' },
    LK: { priceMultiplier: parseFloat('190.00'), currency: 'LKR' },
    NP: { priceMultiplier: parseFloat('130.00'), currency: 'NPR' },
    BD: { priceMultiplier: parseFloat('85.00'), currency: 'BDT' },
    BT: { priceMultiplier: parseFloat('70.00'), currency: 'BTN' },
    MM: { priceMultiplier: parseFloat('1300.00'), currency: 'MMK' },
    LB: { priceMultiplier: parseFloat('1500.00'), currency: 'LBP' },
    JO: { priceMultiplier: parseFloat('0.71'), currency: 'JOD' },
    SY: { priceMultiplier: parseFloat('2150.00'), currency: 'SYP' },
    IQ: { priceMultiplier: parseFloat('1190.00'), currency: 'IQD' },
    YE: { priceMultiplier: parseFloat('250.00'), currency: 'YER' },
    OM: { priceMultiplier: parseFloat('0.38'), currency: 'OMR' },
    QA: { priceMultiplier: parseFloat('3.67'), currency: 'QAR' },
    BH: { priceMultiplier: parseFloat('0.38'), currency: 'BHD' },
    KW: { priceMultiplier: parseFloat('0.30'), currency: 'KWD' },
    AE: { priceMultiplier: parseFloat('3.67'), currency: 'د.إ' },
    YE: { priceMultiplier: parseFloat('250.00'), currency: 'YER' },
    OM: { priceMultiplier: parseFloat('0.38'), currency: 'OMR' }
  }

  async function getCountryFromIP() {
    try {
      const response = await axios.get('https://ipapi.co/json/')
      return response.data.country
    } catch (error) {
      console.error('Error fetching country from IP:', error)
      return 'US'
    }
  }

  const storedData = localStorage.getItem('selectedCountry')
  let selectedCountry = ''
  if (storedData) {
    const parsedData = JSON.parse(storedData)
    selectedCountry = parsedData.value
  } else {
    console.log('No data found in localStorage for selectedCountry')
  }

  useEffect(() => {
    const updateCountryInURL = async () => {
      if (router.pathname === '/bag' && !isCountryUpdated) {
        let updatedUrl = router.asPath
        if (selectedCountry) {
          updatedUrl = `?country=${selectedCountry}`
        } else {
          const country = await getCountryFromIP()
          updatedUrl = `?country=${country}`
        }
        router.replace(updatedUrl, undefined, { shallow: true })
        setIsCountryUpdated(true)
      }
    }

    updateCountryInURL()
  }, [router.pathname, isCountryUpdated, selectedCountry])

  const adjustPrice = (price, country) => {
    const priceNumber = parseFloat(price)
    if (isNaN(priceNumber)) {
      // console.error('Price is not a valid number:', price)
      return price
    }

    const countryInfo = countryCurrencyMap[country] || {}
    const multiplier = countryInfo.priceMultiplier || 1

    if (isNaN(multiplier)) {
      console.error('Multiplier is not a valid number:', multiplier)
      return priceNumber
    }

    const adjustedPrice = priceNumber * multiplier

    const roundedAdjustedPrice = Math.round(adjustedPrice)
    return roundedAdjustedPrice
  }

  const adjustPriceDeduced = (price, country, quantity = 1) => {
    const priceNumber = parseFloat(price) || 0

    const countryInfo = countryCurrencyMap[country] || {}
    const multiplier = countryInfo.priceMultiplier || 1

    const adjustedPrice = priceNumber * multiplier * quantity

    return Math.round(adjustedPrice)
  }

  const adjustCurrency = (currency, country) => {
    const countryInfo = countryCurrencyMap[country] || {}
    return countryInfo.currency || ''
  }

  useEffect(() => {
    const totalItems = products?.reduce((acc, product) => acc + product.node.quantity, 0)

    setProductCount(totalItems)
  }, [products, setProductCount])

  useEffect(() => {
    const isFinalSale = tags && tags.includes('final-sale_badge')
    if (!initialSaleStatusRef.current[productmini?.id]) {
      initialSaleStatusRef.current[productmini?.id] = isFinalSale
      setIsOnSale((prevState) => ({
        ...prevState,
        [productmini?.id]: isFinalSale
      }))
    }
  }, [productmini, tags])

  useEffect(() => {
    async function handleCart() {
      let cartData = JSON.parse(window.localStorage.getItem('CART'))
      const data = cartData ? cartData?.cart?.cart : null

      // Retrieve cart if cart was created not more than 18 hours ago
      if (cartData && new Date().getTime() - cartData.timeCreated < 18 * 60 * 60 * 1000) {
        setIsLoading(true)
        setCartId(data?.id)
        const existingCart = await displayCart(data?.id)
        setProducts(existingCart?.body?.lines?.edges)
        setCost(existingCart?.body?.cart.estimatedCost)
        setIsLoading(false)
        return
      }

      const lines = [{ merchandiseId: 'gid://shopify/ProductVariant/42118892683309', quantity: 1 }]
      let countryData = JSON.parse(window.localStorage.getItem('selectedCountry'))
      if (countryData || selectedCountry) {
        cartData = await createCart(countryData ? countryData : selectedCountry)
        const storage = {
          cart: cartData.body,
          timeCreated: new Date().getTime()
        }
        setCartId(cartData.body.cartId)
        window.localStorage.setItem('CART', JSON.stringify(storage))
      }
    }

    handleCart()

    const interval = setInterval(() => {
      const cartStatus = window.localStorage.getItem('CART_STATUS')
      if (cartStatus && cartStatus === 'dirty') {
        handleCart()
        window.localStorage.setItem('CART_STATUS', 'clean')
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [selectedCountry])

  useEffect(() => {
    const fetchDataAndUpdateCart = async () => {
      const storedCountry = window.localStorage.getItem('selectedCountry')
      const parsedCountry = storedCountry ? JSON.parse(storedCountry)?.value : null

      if (parsedCountry) {
        setCountryDataa(parsedCountry)

        try {
          if (cartId) {
            await updateCartBuyerIdentity(cartId, parsedCountry)
          }
          // const existingCart = await displayCart(cartId)
          // setProducts(existingCart?.body?.lines?.edges)
          // setCost(existingCart?.body?.cart.estimatedCost)
        } catch (error) {
          console.error('Error updating cart or fetching cart data:', error)
        }
      }
    }

    fetchDataAndUpdateCart()
  }, [countryDataa, cartToggled])

  const handleRemoveFromCart = async (itemId) => {
    setBit(true)
    setIsLoading(true)

    const itemToRemove = products.find((item) => item.node.id === itemId)
    if (!itemToRemove) {
      console.error('Item not found in cart')
      setIsLoading(false)
      return
    }

    const isBundle = itemToRemove.node.attributes?.some((attr) => attr.key === '_set_discount')
    const bundleId = itemToRemove.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value

    if (isBundle && bundleId) {
      const bundleItems = products.filter((item) =>
        item.node.attributes?.some((attr) => attr.key === 'bundle_id' && attr.value === bundleId)
      )

      for (const bundleItem of bundleItems) {
        await removeFromCart(cartId, bundleItem.node.id)
      }
    } else {
      await removeFromCart(cartId, itemId)
    }

    window.localStorage.setItem('CART_STATUS', 'dirty')

    setIsLoading(false)
    setBit(false)
    setItemCount(itemCount - 1)
  }

  async function handleRemoveBundle(bundleId) {
    setBit(true)
    const bundleProducts = sortedProducts.filter((item) => {
      if (item && item.node && item.node.attributes) {
        const itemBundleId = item.node.attributes.find((attr) => attr.key === 'bundle_id')?.value
        return itemBundleId === bundleId
      }
      return false
    })

    const itemsToRemove = bundleProducts.map((item) => ({
      ...item,
      node: {
        ...item.node,
        quantity: 0
      }
    }))

    const response = await updateCartItemQuantity(cartId, itemsToRemove)
    setBit(false)
    if (response.statusCode === 200) {
      console.log('Bundle removed successfully')
    } else {
      console.error('Error removing bundle')
    }
  }

  const emptyCart = () => {
    window.localStorage.removeItem('CART')
    window.localStorage.setItem('CART_STATUS', 'dirty')
    setCartId(null)
    setProducts([])
    setCost({})
  }

  const handleIncrement = useCallback(
    async (clickedItemId) => {
      setBit(true)

      const updatedCartItems = products.map((item) => {
        const isTryNow = item.node.attributes?.some((attr) => attr.key === 'try_now')
        if (isTryNow && tryNowCount === 5) {
          console.log('Cannot add more TryNow products. Limit reached.')
          return item
        }

        let updatedQuantity

        if (item.node.id === clickedItemId) {
          updatedQuantity = item.node.quantity + 1
        } else {
          updatedQuantity = item.node.quantity
        }

        return {
          ...item,
          node: {
            ...item.node,
            quantity: updatedQuantity
          }
        }
      })

      const updatedCart = await updateCartItemQuantity(cartId, updatedCartItems)
      setBit(false)
      await fetchLatestCart(cartId)

      if (updatedCart.statusCode === 200) {
        setProducts(updatedCart.body.lines.edges)
        setCost(updatedCart.body.estimatedCost)
      } else {
        console.error('Error updating cart item quantities:', updatedCart.body.message)
      }
    },
    [products, cartId, tryNowCount, newbit]
  )

  const handleDecrement = useCallback(
    async (itemId) => {
      setBit(true)

      setIsUpdatingQuantity(itemId)
      const item = products.find((item) => item.node.id === itemId)
      if (!item) return

      const updatedQuantity = item.node.quantity - 1
      if (updatedQuantity < 1) return

      const updatedItem = {
        ...item,
        node: {
          ...item.node,
          quantity: updatedQuantity
        }
      }

      const updatedCart = await updateCartItemQuantity(cartId, [updatedItem])
      setBit(false)
      await fetchLatestCart(cartId)
      if (updatedCart.statusCode === 200) {
        setProducts(updatedCart.body.lines.edges)
        setCost(updatedCart.body.estimatedCost)
      } else {
        console.error('Error updating cart item quantity:', updatedCart.body.message)
      }

      setIsUpdatingQuantity(null)
    },
    [products, cartId, setProducts, setCost, setIsUpdatingQuantity, setBit]
  )

  const calculateTotalCost = (products, tryNowCount) => {
    if (tryNowCount > 0) {
      return 0
    }

    let totalAmount = 0
    products?.forEach((item) => {
      const itemAmount = parseFloat(item.node.merchandise.priceV2.amount)
      totalAmount += item.node.quantity * itemAmount
    })
    return totalAmount
  }

  const newTotalCost = calculateTotalCost(products, tryNowCount)

  // useEffect(() => {
  //   if (cartId) {
  //     const segments = cartId.split('/Cart/')
  //     const secondSegment = segments && segments[1]
  //     const updatedCheckoutUrl = `https://shop.splits59.com/cart/c/${secondSegment}?key=3d210178ec2d6f42c70bff7c2af281d6`
  //     setUrl(updatedCheckoutUrl)
  //   }
  // }, [cartId])

  function getCARTFromLocalStorage() {
    if (localStorage.getItem('CART')) {
      const cartString = localStorage.getItem('CART')
      const cart = JSON.parse(cartString)
      const cartURL = cart?.cart?.cart?.checkoutUrl
      setUrl(cartURL)
      return cart
    } else {
      return null
    }
  }

  useEffect(() => {
    getCARTFromLocalStorage()
  }, [cartToggled])

  const fetchLatestCart = async () => {
    try {
      const response = await displayCart(cartId)
      if (response.statusCode === 200) {
        const updatedCart = response.body.cart
        setProducts(updatedCart?.lines.edges)
        setCost(updatedCart?.estimatedCost)
      } else {
        console.error('Failed to fetch cart:', response.body.message)
      }
    } catch (error) {
      console.error('Failed to fetch cart:', error)
    }
  }
  const bundleItems = products?.filter((product) =>
    product.node.attributes?.some((attr) => attr.key === '_set_discount')
  )
  useEffect(() => {
    if (cartToggled || !cartToggled) {
      fetchLatestCart()
    }
  }, [cartToggled, cartId, productCount, newbit, simpleproductcount])

  function generateBundleId() {
    return `bundle-${Math.random().toString(36).substr(2, 9)}`
  }

  const processProducts = (products) => {
    return products?.map((product) => {
      const isBundle = product.node.attributes?.some((attr) => attr.key === '_set_discount')
      if (isBundle) {
        product.node.bundleId = generateBundleId()
      }
      return product
    })
  }

  const processedProducts = processProducts(products)
  useEffect(() => {
    const filteredProducts = processedProducts?.filter((product) => {
      return product.node?.attributes.some((attr) => attr.key === '_set_discount')
    })

    setProductsWithBundleDiscount(filteredProducts)
  }, [products])

  const collectiveQuantityByBundle = useMemo(() => {
    const groupedByBundleId = products?.reduce((acc, product) => {
      const bundleId = product.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
      if (bundleId) {
        if (!acc[bundleId]) {
          acc[bundleId] = []
        }
        acc[bundleId].push(product)
      }
      return acc
    }, {})

    const collectiveQuantityByBundle = {}
    for (const bundleId in groupedByBundleId) {
      // Calculate the collective quantity and round it off to the nearest whole number
      collectiveQuantityByBundle[bundleId] = Math.round(
        groupedByBundleId[bundleId].reduce((total, product) => total + product.node.quantity, 0)
      )
    }

    return collectiveQuantityByBundle
  }, [products])

  const sortProducts = (products) => {
    return products?.sort((a, b) => {
      const aHasBundleDiscount = a.node.attributes?.some((attr) => attr.key === '_set_discount')
      const bHasBundleDiscount = b.node.attributes?.some((attr) => attr.key === '_set_discount')
      if (aHasBundleDiscount && !bHasBundleDiscount) {
        return -1
      }
      if (!aHasBundleDiscount && bHasBundleDiscount) {
        return 1
      }
      return 0
    })
  }

  const sortedProducts = products ? sortProducts(products) : null

  useEffect(() => {
    const newTotalCost = calculateTotalCost(products, tryNowCount)
    setCost({
      ...cost,
      totalAmount: {
        amount: adjustPrice(newTotalCost, country),
        currencyCode: adjustCurrency(cost.totalAmount?.currencyCode, country)
      }
    })
  }, [products, tryNowCount, country])

  useEffect(() => {
    let count = 0
    products?.forEach((product) => {
      const hasTryNow = product.node.attributes?.some((attr) => attr.key === 'try_now')
      if (hasTryNow) {
        count += product.node.quantity || 1
      }
    })
    setTryNowCount(count)
  }, [products])

  useEffect(() => {
    let count = 0
    products?.forEach((product) => {
      const hasTryNow = product.node.attributes?.some((attr) => attr.key === 'try_now')
      if (!hasTryNow) {
        count += 1
      }
    })

    setSimpleProductCount(count > 0)
  }, [products])

  useEffect(() => {
    const handleRouteChange = () => {
      setCartToggled(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, setCartToggled])

  const otherAmount = products?.reduce((total, product) => {
    const productPrice = Math.round(product.node.merchandise.priceV2.amount)
    return total + productPrice
  }, 0)
  const totalAmount = otherAmount

  useEffect(() => {
    const groupedBundles = products?.reduce((acc, product) => {
      const bundleId = product.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
      if (bundleId) {
        if (!acc[bundleId]) {
          acc[bundleId] = []
        }
        acc[bundleId].push(product)
      }
      return acc
    }, {})

    setBundles(groupedBundles)
  }, [products])

  const disableqty = products?.map((val) => val.node.quantity)
  const Newueia = products?.map((val) => val.node.merchandise.product.totalInventory)
  let qty
  let totalInv

  for (let i = 0; i < disableqty?.length; i++) {
    qty = disableqty[i]
    totalInv = Newueia[i]
  }

  useEffect(() => {
    const isDisabled = products?.some((val) => val.node.quantity === 0)
    setDisableQuantity(isDisabled)
  }, [products, cartToggled])

  let smallestAvailableQuantity = 0

  // const handleIncrementBundle = async (bundleId) => {
  //   let availableQuantitiesByBundle = {}

  //   sortedProducts?.forEach((product) => {
  //     const productBundleId = product.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
  //     if (productBundleId) {
  //       if (!availableQuantitiesByBundle[productBundleId]) {
  //         availableQuantitiesByBundle[productBundleId] = product.node.attributes?.find(
  //           (attr) => attr.key === 'available-quantity'
  //         )?.value
  //       }
  //     }
  //   })

  //   const bundleProducts = sortedProducts?.filter((item) => {
  //     const itemBundleId = item.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
  //     return itemBundleId === bundleId
  //   })

  //   const availableQuantityForCurrentBundle = availableQuantitiesByBundle[bundleId]
  //   const availableQuantitiesArray = availableQuantityForCurrentBundle.split(',').map(Number)

  //   smallestAvailableQuantity = Math.min(...availableQuantitiesArray)

  //   const currentQuantity = bundleProducts?.reduce((total, item) => {
  //     const itemQuantity = parseInt(item.node.quantity, 10)
  //     return itemQuantity
  //   }, 0)

  //   const availableQuantityNumber = parseInt(availableQuantityForCurrentBundle, 10)

  //   if (
  //     // currentQuantity == availableQuantityNumber - 1 ||
  //     // currentQuantity == availableQuantityNumber - 2 ||
  //     currentQuantity === smallestAvailableQuantity ||
  //     disableqty.some((quantity) => quantity === 0) ||
  //     qty === totalInv
  //   ) {
  //     setDisabledBundles((prev) => ({ ...prev, [bundleId]: true }))
  //     console.log('Cannot increment bundle quantity. Reached available limit.')
  //     return
  //   }

  //   const newInitialQuantities = bundleProducts?.reduce((acc, item) => {
  //     if (!initialQuantities.hasOwnProperty(item.node.id)) {
  //       acc[item.node.id] = item.node.quantity
  //     }
  //     return acc
  //   }, {})

  //   setInitialQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     ...newInitialQuantities
  //   }))

  //   setDisabledBundles((prev) => ({ ...prev, [bundleId]: false }))

  //   const currentInitialQuantities = { ...initialQuantities, ...newInitialQuantities }

  //   const updatedItems = bundleProducts.map((item) => {
  //     const initialQuantity = currentInitialQuantities[item.node.id] || 0

  //     return {
  //       ...item,
  //       node: {
  //         ...item.node,
  //         quantity: item.node.quantity + initialQuantity
  //       }
  //     }
  //   })

  //   const response = await updateCartItemQuantity(cartId, updatedItems)
  //   if (response.statusCode === 200) {
  //     console.log('Cart updated successfully')
  //   } else {
  //     console.error('Error updating cart item quantity')
  //   }
  // }

  // useEffect(() => {
  //   const storedInitialQuantities = localStorage.getItem('initialQuantities')
  //   if (storedInitialQuantities) {
  //     setInitialQuantities(JSON.parse(storedInitialQuantities))
  //   }
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem('initialQuantities', JSON.stringify(initialQuantities))
  // }, [initialQuantities])

  const handleIncrementBundle = async (bundleId) => {
    setBit(true)
    let availableQuantitiesByBundle = {}

    sortedProducts?.forEach((product) => {
      const productBundleId = product.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
      if (productBundleId && !availableQuantitiesByBundle[productBundleId]) {
        availableQuantitiesByBundle[productBundleId] = []
      }
      if (productBundleId) {
        availableQuantitiesByBundle[productBundleId].push(product.node.merchandise.quantityAvailable)
      }
    })

    const bundleProducts = sortedProducts?.filter((item) => {
      const itemBundleId = item.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
      return itemBundleId === bundleId
    })

    const availableQuantitiesForCurrentBundle = availableQuantitiesByBundle[bundleId]
    if (!availableQuantitiesForCurrentBundle) {
      console.error('No available quantities found for the specified bundle.')
      return
    }

    const smallestAvailableQuantity = Math.min(...availableQuantitiesForCurrentBundle)

    const currentQuantity = bundleProducts?.reduce((total, item) => {
      const itemQuantity = parseInt(item.node.quantity, 10)
      return total + itemQuantity
    }, 0)

    if (
      currentQuantity >= smallestAvailableQuantity ||
      disableqty.some((quantity) => quantity === 0) ||
      qty === totalInv
    ) {
      setDisabledBundles((prev) => ({ ...prev, [bundleId]: true }))
      console.log('Cannot increment bundle quantity. Reached available limit.')
      return
    }

    const newInitialQuantities = bundleProducts.reduce((acc, item) => {
      if (!initialQuantities.hasOwnProperty(item.node.id)) {
        acc[item.node.id] = item.node.quantity
      }
      return acc
    }, {})

    setInitialQuantities((prevQuantities) => ({
      ...prevQuantities,
      ...newInitialQuantities
    }))

    setDisabledBundles((prev) => ({ ...prev, [bundleId]: false }))

    const currentInitialQuantities = { ...initialQuantities, ...newInitialQuantities }

    const updatedItems = bundleProducts.map((item) => {
      const initialQuantity = currentInitialQuantities[item.node.id] || 0

      return {
        ...item,
        node: {
          ...item.node,
          quantity: parseInt(item.node.quantity, 10) + parseInt(initialQuantity, 10)
        }
      }
    })

    const response = await updateCartItemQuantity(cartId, updatedItems)
    setBit(false)
    if (response.statusCode === 200) {
      console.log('Cart updated successfully')
    } else {
      console.error('Error updating cart item quantity')
    }
  }

  const handleDecrementBundle = async (bundleId) => {
    setBit(true)
    const bundleProducts = products?.filter(
      (product) => product.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value === bundleId
    )

    const updatedItems = []

    for (const product of bundleProducts) {
      const initialQuantity = initialQuantities[product.node.id] || 0

      const newQuantity = Math.max(product.node.quantity - initialQuantity, 0)

      const updatedItem = {
        ...product,
        node: {
          ...product.node,
          quantity: newQuantity
        }
      }

      updatedItems.push(updatedItem)
    }

    const response = await updateCartItemQuantity(cartId, updatedItems)
    setBit(false)
    if (response.statusCode === 200) {
      setDisabledBundles((prev) => ({ ...prev, [bundleId]: false }))
      console.log('Increment bundle quantity is allowed.')
    } else {
      console.error('Error updating cart item quantity')
    }
  }

  const firstItem = products?.length > 0 && products[0]
  const adjustedPriceForFirstItem = adjustPriceDeduced(
    firstItem.node?.attributes?.find((attr) => attr.key === 'deduced-price')?.value,
    country,
    firstItem?.node?.quantity
  )

  function calculateTotalPriceWithDiscount(items) {
    const totalPrice = items.reduce((total, item) => {
      const quantity = item.node.quantity || 1
      const price = parseFloat(item.node.merchandise.priceV2.amount)
      if (isNaN(price)) {
        console.error('Failed to parse price:', item.node.merchandise.priceV2.amount)
        return total
      }
      return total + price * quantity
    }, 0)

    const hasDiscountedProduct = items.some((item) =>
      item.node.attributes.some((attr) => attr.key === '_set_discount' && attr.value === 'SET15')
    )

    let discountRate = hasDiscountedProduct ? 0.15 : 0.2
    const discountedPrice = totalPrice * (1 - discountRate)

    let roundedDiscountedPriceStr = discountedPrice.toString()
    const decimalIndex = roundedDiscountedPriceStr.indexOf('.')
    if (decimalIndex > -1) {
      roundedDiscountedPriceStr = roundedDiscountedPriceStr.substring(0, decimalIndex + 1)
    }

    const roundedDiscountedPrice = parseFloat(roundedDiscountedPriceStr)

    return roundedDiscountedPrice
  }

  function calculateTotalPrice(items) {
    const totalPrice = items.reduce((total, item) => {
      const quantity = item.node.quantity || 1
      const price = parseFloat(item.node.merchandise.priceV2.amount)
      if (isNaN(price)) {
        console.error('Failed to parse price:', item.node.merchandise.priceV2.amount)
        return total
      }
      return total + price * quantity
    }, 0)
    return totalPrice
  }

  function calculateTotalPriceWithDiscountt(items, country) {
    const totalPrice = items?.reduce((total, item) => {
      const quantity = item.node.quantity || 1
      const price = parseFloat(item.node.merchandise.priceV2.amount)

      if (isNaN(price)) {
        console.error('Failed to parse price:', item.node.merchandise.priceV2.amount)
        return total
      }

      return total + price * quantity
    }, 0)

    const hasDiscountedProduct = items?.some((item) =>
      item.node.attributes?.some((attr) => attr.key === '_set_discount' && attr.value === 'SET15')
    )

    let discountRate = hasDiscountedProduct ? 0.17 : 0.2
    const discountedPrice = totalPrice

    const adjustedPrice = adjustPrice(discountedPrice, country)

    return adjustedPrice
  }

  const hasBundleDiscount = products?.some(
    (product) => product.node.attributes?.find((attr) => attr.key === '_set_discount')?.value
  )

  const bundleProductsTotalWithDiscount = calculateTotalPriceWithDiscountt(sortedProducts)

  let availableQuantitiesByBundle = {}

  sortedProducts?.forEach((product) => {
    const bundleId = product.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
    const quantity = product.node.attributes?.find((attr) => attr.key === 'available-quantity')?.value
    if (bundleId && quantity) {
      const quantityNumber = parseInt(quantity, 10)
      if (!availableQuantitiesByBundle[bundleId]) {
        availableQuantitiesByBundle[bundleId] = quantityNumber
      } else {
        // console.warn(`Duplicate bundle ID found: ${bundleId}. This might indicate an issue with your data.`)
      }
    }
  })

  const isCartEmpty = productCount == 0

  const combineFirstTwoSetTitleSetsValues = () => {
    const filteredItems = products?.filter((item) =>
      item.node.attributes?.some((attr) => attr.key === '_set_title_sets')
    )

    const uniqueTitleSetValues = Array.from(
      new Set(
        filteredItems?.map((item) => item.node.attributes.find((attr) => attr.key === '_set_title_sets')?.value || '')
      )
    ).join(' & ')

    setCustomSetsName(uniqueTitleSetValues)
  }

  useEffect(() => {
    combineFirstTwoSetTitleSetsValues()
  }, [cartToggled, products, selectedCountry])

  useEffect(() => {
    const currencyCodes = products?.map((product) => product.node.merchandise.priceV2.currencyCode)
    const currVal = currencyCodes?.length > 0 ? currencyCodes[0] : ''
    setCurrencyValue(currVal)
  }, [products, cartToggled, selectedCountry])

  return (
    <CartContainer>
      <BagText>
        <div>
          <TextWrap>
            {productCount > 0 ? (
              <>
                {productCount} Item{productCount !== 1 ? 's' : ''} Added in Bag
              </>
            ) : (
              'Your Bag'
            )}
          </TextWrap>
        </div>
        <div>
          <CloseButton onClick={() => setCartToggled(false)}>
            <span>Close</span>
            <IconClose />
          </CloseButton>
        </div>
      </BagText>

      {Object.keys(cost ?? {})?.length > 0 ? (
        <ProductGrid>
          <CartProducts
            isBundle={hasBundleDiscount}
            selectedCountry={selectedCountry}
            tryNowCount={tryNowCount}
            id={'cart-products'}
          >
            {selectedCountry === 'US' && productCount > 0 && (
              <div style={{ width: '100%' }}>
                <BannerDiv>
                  <ShippingProgressTracker
                    isInMiniCart={true}
                    totalPricePurchases={
                      hasBundleDiscount
                        ? bundleProductsTotalWithDiscount
                        : tryNowCount
                        ? bundleProductsTotalWithDiscount
                        : newTotalCost
                    }
                  ></ShippingProgressTracker>
                  {/* YOU ARE QUALIFIED FOR FREE SHIPPING! (US ONLY) */}
                </BannerDiv>
              </div>
            )}
            {sortedProducts &&
              Object.entries(
                sortedProducts?.reduce((acc, item) => {
                  const bundleId = item.node.attributes?.find((attr) => attr.key === 'bundle_id')?.value
                  if (bundleId) {
                    acc[bundleId] = [...(acc[bundleId] || []), item]
                  } else {
                    acc['non-bundle'] = [...(acc['non-bundle'] || []), item]
                  }
                  return acc
                }, {})
              ).map(([bundleId, items], index) => (
                <React.Fragment key={bundleId}>
                  {productsWithBundleDiscount?.some((product) => product.node.id === items[0].node.id) && (
                    <>
                      <div style={{ display: 'flex' }}>
                        <span style={{ color: 'black', flexGrow: 1 }}>
                          {items[0].node.attributes?.find((attr) => attr.key === 'bundle_title')?.value ||
                            customsetsname}
                        </span>

                        <span style={{ color: 'grey', textDecoration: 'line-through' }}>
                          {`${adjustCurrency(
                            items[0].node.merchandise.priceV2.currencyCode,
                            selectedCountry
                          )}${new Intl.NumberFormat().format(calculateTotalPrice(items))} ${
                            selectedCountry === 'US' ? ' USD' : `${currencyval}`
                          }`}
                        </span>

                        <span style={{ marginLeft: 'auto', paddingLeft: '0.2rem' }}>
                          {`${adjustCurrency(
                            items[0].node.merchandise.priceV2.currencyCode,
                            selectedCountry
                          )}${new Intl.NumberFormat().format(calculateTotalPriceWithDiscount(items))} ${
                            selectedCountry === 'US' ? ' USD' : `${currencyval}`
                          }`}
                        </span>
                      </div>
                      <span style={{ color: 'red', fontWeight: '300' }}>
                        {items[0].node.attributes?.find((attr) => attr.key === '_set_discount')?.value}%OFF
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          marginTop: '10px',
                          marginLeft: '16px',
                          marginBottom: '1rem'
                        }}
                      >
                        <DecrementButton onClick={() => handleDecrementBundle(bundleId)}>-</DecrementButton>

                        {/* <span>{items.reduce((total, item) => total + item.node.quantity, 0)}</span> */}
                        <span>
                          {items[0].node.attributes?.find((attr) => attr.key === 'bundle_title')?.value
                            ? Math.round(collectiveQuantityByBundle[bundleId] / 3) > 0
                              ? `${Math.round(collectiveQuantityByBundle[bundleId] / 3)}`
                              : 0
                            : Math.round(collectiveQuantityByBundle[bundleId] / 2) > 0
                            ? `${Math.round(collectiveQuantityByBundle[bundleId] / 2)}`
                            : 0}
                        </span>

                        <IncrementButton
                          disabled={disabledBundles[bundleId]}
                          onClick={() => handleIncrementBundle(bundleId)}
                          style={{
                            color: disabledBundles[bundleId] ? 'gray' : 'inherit',
                            cursor: disabledBundles[bundleId] ? 'not-allowed' : 'pointer'
                          }}
                        >
                          +
                        </IncrementButton>
                        <RemoveBtn onClick={() => handleRemoveBundle(bundleId)}>
                          <DeleteItemBundle>Remove Set</DeleteItemBundle>
                        </RemoveBtn>
                      </div>
                    </>
                  )}

                  {items.map((item, index) => {
                    item = item.node

                    const isBundle = item.attributes?.some((attr) => attr.key === '_set_discount')
                    const productPriceOrginal = item.attributes?.find((attr) => attr.key === 'original_price')

                    const finalComparePrice = item.merchandise.compareAtPrice?.amount

                    const isOneItemLeft = item.attributes?.some((attr) => attr.key === 'isAvailable')
                    const moreItemLeft = item.attributes?.find((attr) => attr.key === 'quantity_available')
                    const moreItemsLeft = moreItemLeft ? moreItemLeft.value : undefined

                    const isInPreOrder = item.attributes?.find((attr) => attr.key === 'Purple Dot Pre-order')
                    const PurpleDotVal = isInPreOrder ? isInPreOrder?.value : null
                    const compareAtPriceAttribute = item.attributes?.find((attr) => attr.key === 'compareAtPrice')
                    const CompareAtPrice = compareAtPriceAttribute ? compareAtPriceAttribute.value : undefined

                    const selectedOptions = item.merchandise?.selectedOptions

                    const lengthOption = selectedOptions?.filter((option) => option.name === 'LENGTH')
                    const SizeOption = selectedOptions?.filter((option) => option.name === 'Size')

                    const newselectedOptions = lengthOption?.length > 0 ? lengthOption[0]?.value : undefined
                    const newselectedOptionsSize = SizeOption?.length > 0 ? SizeOption[0]?.value : undefined

                    const titleee = item.merchandise.title === 'Default Title'
                    const ProductT = item.merchandise.title
                    const titleParts = item.merchandise.title.split('/')

                    let color, size

                    if (titleParts.length === 2) {
                      color = titleParts[0]
                      size = titleParts[1]
                    } else if (titleParts.length > 2) {
                      color = titleParts.slice(0, 2).join('/')
                      size = titleParts.slice(2, 4).join('/')
                    } else {
                      color = titleParts[0]
                      size = undefined
                    }

                    const title = item.merchandise.product.title.split('-')
                    const formattedtitle = title[0]
                    const finalsale = item.attributes?.some((val) => val.value === 'Final Sale')
                    const TryNnow = item.attributes?.some((val) => val.value === 'TryNow')
                    let totalPrice = item.merchandise.priceV2.amount
                    if (isBundle) {
                      totalPrice = item.quantity * item.merchandise.priceV2.amount
                    }
                    let colorVal, LengthVal

                    const colorOption = item.merchandise.product?.options.find((option) => option.name === 'Color')
                    if (colorOption) {
                      colorVal = colorOption.values[0]
                    }
                    const LengthOption = item.merchandise.product?.options.find((option) => option.name === 'LENGTH')
                    if (LengthOption) {
                      LengthVal = LengthOption.values[0]
                    }

                    const adjustedPrice = adjustPrice(item.merchandise.priceV2.amount, selectedCountry)
                    const actualadjustedPrice = item.merchandise.priceV2.amount

                    const giftCard = item.merchandise.product.title === 'Gift card'

                    const adjustedCurrency = adjustCurrency(
                      item.node?.merchandise.priceV2.currencyCode,
                      selectedCountry
                    )

                    const isQuantityOne = item.quantity == 1

                    if (isBundle && isQuantityOne) {
                      const repeatedItems = Array.from({ length: item.quantity }, (_, i) => ({ ...item, quantity: 1 }))
                      return repeatedItems.map((repeatedItem, repeatedIndex) => (
                        <React.Fragment key={`${index}-${repeatedIndex}`}>
                          <Product key={`${index}-${repeatedIndex}`} isBundle={isBundle}>
                            <Link href={`/products/${repeatedItem?.merchandise.product.handle}`}>
                              <BundleIMage src={repeatedItem.merchandise.image?.url} />
                            </Link>
                            <ProductDetails>
                              <TitleAndPriceContainer>
                                <Link href={`/products/${repeatedItem?.merchandise.product.handle}`}>
                                  <ProductName>{formattedtitle}</ProductName>
                                </Link>

                                {/* {finalComparePrice && (
                                  <ProductCostCompareAT>
                                    {`${adjustedCurrency}${new Intl.NumberFormat().format(
                                      finalComparePrice * item.quantity
                                    )}${selectedCountry === 'US' ? ' USD' : item.merchandise?.priceV2.currencyCode}`}
                                  </ProductCostCompareAT>
                                )} */}
                                {!isBundle && (
                                  <ProductCost>
                                    {`${adjustedCurrency}${new Intl.NumberFormat().format(actualadjustedPrice)}${
                                      selectedCountry === 'US' ? ' USD' : item.merchandise?.priceV2.currencyCode
                                    }`}
                                  </ProductCost>
                                )}
                              </TitleAndPriceContainer>
                              <ProductVariant>Color: {colorVal}</ProductVariant>
                              {!LengthVal ? (
                                <ProductVariantSecond>Size: {newselectedOptionsSize}</ProductVariantSecond>
                              ) : (
                                <LengthVariant>
                                  Length/Size: {newselectedOptions} /{newselectedOptionsSize}
                                </LengthVariant>
                              )}
                              <ProductVariantSecond>Quantity: {repeatedItem.quantity}</ProductVariantSecond>
                              {finalsale ? <FInalSaleItem>Final Sale</FInalSaleItem> : null}
                              {TryNnow ? <TryNowItem>Try Before You Buy</TryNowItem> : null}
                              {/* {item?.merchandise?.quantityAvailable === 1 ? (
                                <OneLeftItem>Only 1 Left</OneLeftItem>
                              ) : null}
                              {item?.merchandise?.quantityAvailable === 2 ? (
                                <OneLeftItem>{`Only 2 Left`}</OneLeftItem>
                              ) : null}
                              {item?.merchandise?.quantityAvailable === 3 ? (
                                <OneLeftItem>{`Only 3 Left`}</OneLeftItem>
                              ) : null} */}
                              {/* <ProductSubContainer> */}
                              <div style={{ padding: '0' }}>
                                {!isBundle && (
                                  <QuantityContainer style={{ marginTop: '0.4rem' }}>
                                    <DecrementButton onClick={() => handleDecrement(repeatedItem.id)}>
                                      -
                                    </DecrementButton>
                                    {repeatedItem.quantity}
                                    <IncrementButton onClick={() => handleIncrement(repeatedItem.id)}>
                                      +
                                    </IncrementButton>
                                  </QuantityContainer>
                                )}
                                {!isBundle && (
                                  <RemoveBtn onClick={() => handleRemoveFromCart(repeatedItem.id)}>
                                    <DeleteItem>Remove</DeleteItem>
                                  </RemoveBtn>
                                )}
                              </div>
                              {/* </ProductSubContainer> */}
                            </ProductDetails>
                          </Product>
                        </React.Fragment>
                      ))
                    }

                    return (
                      <React.Fragment key={`${index}`}>
                        <Product key={`${index}`} isBundle={isBundle}>
                          <Link href={`/products/${item?.merchandise.product.handle}`}>
                            <ImageSimple
                              tryNowCount={tryNowCount}
                              src={item.merchandise.image?.url}
                              aria-label={`Image of ${formattedtitle}`}
                              tabIndex={0}
                            />
                          </Link>
                          <ProductDetails>
                            <TitleAndPriceContainer>
                              <Link href={`/products/${item?.merchandise.product.handle}`}>
                                <ProductName tabIndex={0}>{formattedtitle}</ProductName>
                              </Link>
                              {!isBundle && finalComparePrice && (
                                <ProductCostCompareAT>
                                  {`${adjustedCurrency}${new Intl.NumberFormat().format(
                                    finalComparePrice * item.quantity
                                  )}${selectedCountry === 'US' ? ' USD' : item.merchandise?.priceV2.currencyCode}`}
                                </ProductCostCompareAT>
                              )}

                              {!isBundle && (
                                <ProductCost>
                                  {isNaN(actualadjustedPrice * item.quantity)
                                    ? `${adjustedCurrency}${new Intl.NumberFormat().format(
                                        actualadjustedPrice * item.quantity
                                      )}${selectedCountry === 'US' ? ' USD' : ''}`
                                    : `${adjustedCurrency}${new Intl.NumberFormat().format(
                                        actualadjustedPrice * item.quantity
                                      )}${selectedCountry === 'US' ? ' USD' : item.merchandise?.priceV2.currencyCode}`}
                                </ProductCost>
                              )}
                            </TitleAndPriceContainer>
                            {!titleee && item.merchandise.product.title !== 'Gift card' && (
                              <ProductVariant>Color: {colorVal}</ProductVariant>
                            )}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {!titleee && !LengthVal ? (
                                <ProductVariantSecond>
                                  {item.merchandise.product.title === 'Gift card' ? (
                                    <>Title: {item.merchandise.title}</>
                                  ) : (
                                    <>
                                      Size:{' '}
                                      {item.merchandise.id === 'gid://shopify/ProductVariant/42118892683309' ||
                                      item.merchandise.product.handle === 'med-size-tote'
                                        ? 'O/S'
                                        : newselectedOptionsSize}
                                    </>
                                  )}
                                </ProductVariantSecond>
                              ) : LengthVal ? (
                                <ProductVariantSecond>
                                  Length/Size: {newselectedOptions}/ {newselectedOptionsSize}
                                </ProductVariantSecond>
                              ) : null}

                              {item?.merchandise?.quantityAvailable === 3 && !isBundle ? (
                                <MorLeftItem>{`Only 3 Left`}</MorLeftItem>
                              ) : null}
                              {item?.merchandise?.quantityAvailable === 2 && !isBundle ? (
                                <MorLeftItem>{`Only 2 Left`}</MorLeftItem>
                              ) : null}
                              {item?.merchandise?.quantityAvailable === 1 && !isBundle ? (
                                <MorLeftItem>Only 1 Left</MorLeftItem>
                              ) : null}
                            </div>
                            {/* {LengthVal && <LengthVariant>Length/Size: {size}</LengthVariant>} */}
                            {isBundle && <ProductVariantSecond>Quantity: {item.quantity}</ProductVariantSecond>}
                            {titleee && <ProductVariantSecond>Title: {ProductT}</ProductVariantSecond>}
                            {finalsale ? <FInalSaleItem>Final Sale</FInalSaleItem> : null}
                            {TryNnow ? <TryNowItem>Try Before You Buy</TryNowItem> : null}
                            {isInPreOrder ? <FInalSaleItem>{PurpleDotVal}</FInalSaleItem> : null}

                            {/* <ProductSubContainer> */}
                            <div style={{ padding: '0' }}>
                              {!isBundle && (
                                <QuantityContainer style={{ marginTop: '0.4rem' }}>
                                  <DecrementButton onClick={() => {
                                    
                                    handleDecrement(item.id)
                                    let variantSchema = [item]
                                    Analytics.quantityDecrease(variantSchema, item.quantity)
                                  
                                  
                                  }}>-</DecrementButton>
                                  {item.quantity}
                                  <IncrementButton
                                    onClick={() => {
                                      
                                      handleIncrement(item.id)
                                      let variantSchema = [item]
                                      Analytics.quantityIncrease(variantSchema, item.quantity)
                                    
                                    }}
                                    disabled={
                                      Number(item.quantity) == Number(item?.merchandise?.quantityAvailable) ||
                                      tryNowCount === 5
                                    }
                                    style={
                                      Number(item.quantity) == Number(item?.merchandise?.quantityAvailable) ||
                                      tryNowCount === 5
                                        ? { color: 'lightgray', cursor: 'not-allowed' }
                                        : {}
                                    }
                                  >
                                    +
                                  </IncrementButton>
                                </QuantityContainer>
                              )}
                              {!isBundle && (
                                <RemoveBtn giftCard={giftCard} onClick={() => {
                                  handleRemoveFromCart(item.id);
                                  let variantSchema = [item]
                                  Analytics.removeFromCart(variantSchema, item.quantity)
                                  console.log('remove', item);

                                }
 

                                }>
                                  <DeleteItem finalsale={finalsale}>Remove</DeleteItem>
                                </RemoveBtn>
                              )}
                            </div>
                            {/* </ProductSubContainer> */}
                          </ProductDetails>
                        </Product>
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              ))}

            {products && products.length > 0 && tryNowCount > 0 && tryNowCount < 5 && (
              <div style={{ marginTop: '-7px', marginBottom: '20px' }}>
                <TryNowCartWidget itemsLeft={5 - tryNowCount}></TryNowCartWidget>
              </div>
            )}

            {upsellProducts && cartToggled && upsellProducts.length >= 0 && upSell?.data?.upsellProduct?.showProducts && (
              <div>
                <UpsellWrap isCartEmpty={isCartEmpty} tryNowCount={tryNowCount}>
                  {loadingUpSell ? (
                    <FitLoader />
                  ) : (
                    <>
                      {/* <Link href={`/products/${handle}`}> */}
                      <UpsellTitle>
                        {upSell?.data?.upsellProduct?.title ? upSell?.data?.upsellProduct?.title : ''}
                      </UpsellTitle>
                      {/* </Link> */}
                      <UpsellSlider upsellProducts={upsellProducts} isSidebar />
                    </>
                  )}
                </UpsellWrap>
              </div>
            )}
          </CartProducts>
          {productCount > 0 && (
            <StaticContainer selectedCountry={selectedCountry} tryNowCount={tryNowCount}>
              <ExtraDiv>
                {/* <TotalDiv>
                  <span>Estimated Total:</span>
                  {!hasBundleDiscount ? (
                    <span>
                      {tryNowCount > 0
                        ? `${adjustCurrency(cost.totalAmount.currencyCode, selectedCountry)}0${
                            selectedCountry === 'US' ? ' USD' : ''
                          }`
                        : newTotalCost
                        ? `${adjustCurrency(cost.totalAmount.currencyCode, selectedCountry)} 
                            ${newTotalCost}
                          ${selectedCountry === 'US' ? ' USD' : ''}`
                        : `${adjustCurrency(cost.totalAmount.currencyCode, selectedCountry)} ${
                            cost.totalAmount.amount
                          }${selectedCountry === 'US' ? ' USD' : ''}`}
                    </span>
                  ) : (
                    <span>
                      {`${adjustCurrency(
                        sortedProducts[0]?.node?.merchandise?.priceV2?.currencyCode,
                        selectedCountry
                      )}${bundleProductsTotalWithDiscount}${selectedCountry === 'US' ? ' USD' : ''}`}
                    </span>
                  )}
                </TotalDiv> */}
                <TotalDiv tryNowCount={tryNowCount}>
                  <span style={{ fontSize: '19px' }}>Estimated Total:</span>
                  {!hasBundleDiscount ? (
                    <span>
                      {tryNowCount > 0
                        ? `${adjustCurrency(
                            cost.totalAmount.currencyCode,
                            selectedCountry
                          )}${new Intl.NumberFormat().format(0)}`
                        : newTotalCost
                        ? `${adjustCurrency(
                            cost.totalAmount.currencyCode,
                            selectedCountry
                          )}${new Intl.NumberFormat().format(newTotalCost)}`
                        : `${adjustCurrency(
                            cost.totalAmount.currencyCode,
                            selectedCountry
                          )}${new Intl.NumberFormat().format(cost.totalAmount.amount)}`}{' '}
                      {selectedCountry === 'US' ? ' USD' : `${currencyval}`}
                    </span>
                  ) : (
                    <span>
                      {`${adjustCurrency(
                        sortedProducts[0]?.node?.merchandise?.priceV2?.currencyCode,
                        selectedCountry
                      )}${new Intl.NumberFormat().format(bundleProductsTotalWithDiscount)} ${
                        selectedCountry === 'US' ? ' USD' : `${currencyval}`
                      }`}
                    </span>
                  )}
                </TotalDiv>
              </ExtraDiv>

              <ButtonSecondary href={'/bag'} onClick={() => setCartToggled(false)} style={{ width: '100%' }}>
                View Bag
              </ButtonSecondary>
              <div style={{ paddingTop: '10px' }}>
                <ButtonPrimary style={{ width: '100%' }} href={preorderCheckout ? '/bag' : url}>
                  Checkout
                </ButtonPrimary>
              </div>
              <ParentContainer>
                {data.country?.value == 'US' && (
                  <CatchDiv selectedCountry={selectedCountry}>
                    <style jsx global>
                      {`
                        catch-callout:defined {
                          font-family: DINcondensed;
                        }
                      `}
                    </style>
                    {/* <catch-callout
                      price={tryNowCount ? { amount: 0 } : newTotalCost * 100}
                      border-style="none-no-padding"
                      theme="light-mono"
                    /> */}
                  </CatchDiv>
                )}
                <div style={{ paddingBottom: '13px' }}>
                  {tryNowCount && data.country?.value == 'US' ? <TryNowCartDisclaimer /> : null}
                </div>
              </ParentContainer>
            </StaticContainer>
          )}
        </ProductGrid>
      ) : (
        <CartSubContainer>
          <CartMessage>Your cart is empty.</CartMessage>
        </CartSubContainer>
      )}
      {productCount === 0 && (
        <CartSubContainer style={{ position: 'fixed', bottom: 0 }}>
          <CartMessage>Your bag is empty.</CartMessage>
        </CartSubContainer>
      )}
    </CartContainer>
  )
}

const cartLinesUpdateQuery = `
 mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        lines(first: 100) {
          edges {
            node {
              id
              quantity
              attributes { 
                key
                value
            }
              merchandise {
                ... on ProductVariant {
                 id
                 title
                 image {
                    url(transform: {maxWidth: 500})
                    altText
                 }
                 priceV2 {
                    amount
                    currencyCode
                 }
                 product {
                    title
                    handle
                    totalInventory
                    options (first: 5) {
                      name
                      values
                    }
                    images(first: 1) {
                      edges {
                        node {
                          url(transform: {maxWidth: 500})
                          altText
                        }
                      }
                    }
                 }
                }
              }
            }
          }
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
 }
`

export async function updateCartItemQuantity(cartId, items) {
  const lines = items.map((item) => ({
    id: item.node.id,
    quantity: item.node.quantity,
    attributes: item.node.attributes
  }))

  const response = await storefront(cartLinesUpdateQuery, {
    cartId,
    lines
  })

  if (!response.data) {
    return {
      statusCode: 500,
      body: JSON.stringify({ message: 'Error updating cart item quantity' })
    }
  }
  return {
    statusCode: 200,
    body: response.data.cartLinesUpdate?.cart
  }
}

const cartBuyerIdentityUpdateMutation = `
mutation CartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
  cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
    cart {
      id
      buyerIdentity {
        countryCode
      }
    }
  }
}`

export async function updateCartBuyerIdentity(cartId, countryCode) {
  const variables = {
    cartId: cartId,
    buyerIdentity: {
      countryCode: `${countryCode}`
    }
  }

  try {
    const response = await storefront(cartBuyerIdentityUpdateMutation, variables)
    if (!response.data) {
      return {
        statusCode: 500,
        body: JSON.stringify({ message: 'Error updating cart buyer identity' })
      }
    }
    return {
      statusCode: 200,
      body: JSON.stringify(response.data.cartBuyerIdentityUpdate.cart)
    }
  } catch (error) {
    console.error('Error during cart buyer identity update:', error)
    return {
      statusCode: 500,
      body: JSON.stringify({ message: 'Internal server error' })
    }
  }
}
