import { createContext, useEffect, useState, useMemo } from "react";
import { useRouter } from "next/router";
import React from "react";

const UIContext = createContext(null);

function UIProvider({ children }) {

  const [isCartOpen, setCartOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [lastClickedCollectionViewType, setLastClickedCollectionViewType] = useState(null);

  const router = useRouter();

  useEffect(() => {

    const handleRouteChange = () => {
      setCartOpen(false);
      setNavOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    }
  });

  const contextValue = useMemo(() => {
    return {
      isCartOpen,
      setCartOpen,
      isNavOpen,
      setNavOpen,
      lastClickedCollectionViewType,
      setLastClickedCollectionViewType
    };
  }, [isCartOpen, setCartOpen, isNavOpen, setNavOpen, lastClickedCollectionViewType, setLastClickedCollectionViewType]);

  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
}

function useUI() {
  const context = React.useContext(UIContext);
  const { isCartOpen, isNavOpen, lastClickedCollectionViewType, setLastClickedCollectionViewType } = context;
  const memoizedContextValue = useMemo(() => {
    return {
      isCartOpen,
      isNavOpen,
      lastClickedCollectionViewType,
      setLastClickedCollectionViewType,
      openCart: () => {
        context.setCartOpen(true);
      },
      closeCart: () => {
        context.setCartOpen(false);
      },
      openNav: () => {
        context.setNavOpen(true);
      },
      closeNav: () => {
        context.setNavOpen(false);
      },
    };
  }, [context, isCartOpen, isNavOpen, lastClickedCollectionViewType, setLastClickedCollectionViewType]);

  return memoizedContextValue;
}

export { UIProvider, useUI };