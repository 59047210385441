import React, { useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'

import { ButtonBlock } from '@button'
import Image from '@image'
import styled from '@emotion/styled'
import { theme, mq, rs } from '@theme'

import putImageSizes from '../../helpers/putImageSizes'
import ProductPrice from '../ProductPrice'
import { ButtonRaw } from '@button'
import SwtachDot from '../SwatchDot'

import getBadgesFromProductTags from '../../helpers/getBadgesFromProductTags'
import { useSettings } from '@/hooks/Settings'
import { useAnalytics } from '@/hooks/Analytics'
import { useGlobalE } from '@/hooks/Global-E'
import useWindows from '@/hooks/useWindows'
import Link from 'next/link'
// import QuickBuyModal from '@/components/QuickBuyModal'
// import IconQuickBuy from '@/components/_icons/IconQuickBuy'
// import UpsellProductDetails from '../UpsellProductDetails'
const ProductDetails = dynamic(() => import('@/components/ProductDetails'))

const FinalSale = styled.div`
  position: absolute;
  top: 15%;
  right: 3px;
  .final-sale {
    color: ${theme.colors.red};
    ${theme.font.body03}
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin-top: 0.05em;
  }
`

const PriceWrapper = styled.div`
  ${rs('display', ['block', null, 'inline'])}
  position: absolute;
  top: 0;
  right: 0px;
  ${theme.font.caps07}
  s {
    color: ${theme.colors.mono500};
  }
`
// const Separator = styled.div`
//   ${rs('display', ['none', null, 'inline'])}
// `

const ColorText = styled.div`
  ${theme.font.caps08}
  font-weight: 300;
  letter-spacing: -0.22px;
  position: absolute;
  top: 32%;
  left: 25%;

  @media (max-width: 550px) {
    left: 36%;
  }
`
const ProductTitle = styled.div`
  position: absolute;
  top: 0px;
  left: 35%;
  gap: 60px;
  ${theme.font.caps07}
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin-right: 0.25em;
  }
  s {
    color: ${theme.colors.mono500};
  }
`
const RelatedProducts = styled.div`
  position: absolute;
  top: 47%;
  left: 25%;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 4px;
  margin-top: 2px;
  transition: opacity 200ms;

  display: inline-grid;

  span {
    ${theme.font.lightCaps01}
  }

  @media (max-width: 550px) {
    left: 36%;
  }
`

// const TextWrap = styled.div`
//   display: grid;
//   justify-content: space-between;
// `
const ImageWrap = styled.div`
  position: relative;
  width: 20%;
  // ${theme.size.xs('margin-bottom')}

  @media (max-width: 550px) {
    width: 33%;
  }
`

const Root = styled.div`
  position: relative;
`
const SecondaryImage = styled.div`
  position: absolute;
  transition: opacity 200ms;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${(p) => (p.isVisible ? '1' : '0')};
`

const Badge = styled.div`
  display: inline;
  float: left;
  ${theme.font.caps08}
  font-size: 11px !important;
  background: black;
  color: white;
  padding: 1px 3px;
  white-space: nowrap;
`
const BadgesInner = styled.div`
  position: absolute;
  display: inline-flex;
  gap: 4px;
  grid-auto-flow: column;
  ${mq['lg']} {
    position: relative;
  }
`
const BadgesWrap = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Flex = styled.div`
  position: absolute;
  top: 0px;
  height: 50px;
  left: 25%;

  .css-zeqzln {
    position: unset !important;
  }

  .css-yops7z {
    position: unset !important;
  }

  @media (max-width: 550px) {
    left: 36%;
  }
`
// const QuickBuyButton = styled.button`
//   appearance: none;
//   border: 0;
//   width: 44px;
//   height: 44px;
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: white;
//   border-radius: 50%;
//   bottom: 10px;
//   right: 10px;
//   z-index: 1;
//   box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
//   transition: all 150ms;
//   cursor: pointer;
//   color: black;
//   ${(p) => (p.isActive ? `background: ${theme.colors.mono200}; color: ${theme.colors.mono700};` : ``)}
//   svg {
//     width: 24px;
//   }
//   @media (hover: hover) and (pointer: fine) {
//     opacity: ${(p) => (p.isVisible ? '1' : '0')};
//   }
// `

const UpsellProductCard = ({ product, isHalfOnDesktop, preload, collectionHandle, disableQuickBuy, isInCartPage }) => {
  const [currentProduct, setCurrentProduct] = useState(product)
  const [isQuickBuyOpen, setQuickBuyOpen] = useState(false)
  const [isHovered, setHovered] = useState(false)
  const { productColors } = useSettings()
  const { data } = useGlobalE() || {}
  const { width } = useWindows()
  const [hoverColor, setHoverColor] = useState(null)

  const isMobile = width < 768

  const Analytics = useAnalytics()
  const refObserverContainer = useRef(null)

  const hasBeenViewed = useRef(false)
  // get new product when product changes
  useEffect(() => {
    setCurrentProduct(product)
  }, [product])

  useEffect(() => {
    const observer = new IntersectionObserver(callbackObserverFunction, options)
    if (refObserverContainer.current) observer.observe(refObserverContainer.current)

    return () => {
      if (refObserverContainer.current) observer.unobserve(refObserverContainer.current)
    }
  }, [refObserverContainer])

  if (!product) {
    return null
  }

  const isBundle = product.bundlePieces?.length > 1

  const _sizes = putImageSizes(['100vw', null, '50vw', isHalfOnDesktop ? '50vw' : '33vw'])

  const relatedProducts = product.relatedProducts

  const badges = getBadgesFromProductTags(product.tags)

  if (product.bundleMessage) {
    badges.push(product.bundleMessage)
  }

  let primaryImage = currentProduct.primaryImage

  if (currentProduct.featuredImageCollections) {
    let displayFeaturedImage = currentProduct.featuredImageCollections?.includes(collectionHandle)
    if (displayFeaturedImage) {
      primaryImage = displayFeaturedImage
        ? currentProduct.featuredImage ?? currentProduct.primaryImage
        : currentProduct.primaryImage
    }
  }
  const callbackObserverFunction = (entries) => {
    if (hasBeenViewed.current) {
      return
    }
    const [entry] = entries
    if (entry.isIntersecting) {
      Analytics.productImpression(product)
      hasBeenViewed.current = true
    }
  }
  const options = {
    root: null,
    threshold: 1.0
  }

  return (
    <Root
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
    >
      <ImageWrap>
        <ButtonBlock
          id={`productButton_${currentProduct.handle}`}
          href={`/products/${currentProduct.handle}`}
          onClick={() => Analytics.productClick(currentProduct)}
          // tabIndex={-1}
        >
          <Image image={primaryImage} sizes={_sizes} priority={preload} />
          {currentProduct.secondaryImage && (
            <SecondaryImage isVisible={isHovered}>
              <Image image={currentProduct.secondaryImage} sizes={_sizes} backgroundColor={'transparent'} />
            </SecondaryImage>
          )}
          {badges.length > 0 && (
            <BadgesWrap>
              <BadgesInner>
                {badges.map((badge, i) => {
                  return <Badge key={i}>{badge}</Badge>
                })}
              </BadgesInner>
            </BadgesWrap>
          )}
        </ButtonBlock>
      </ImageWrap>

      <Flex id="flex">
        <Link href={`/products/${currentProduct.handle}`}>
          <ProductTitle isInUpsell>
            <span>{isMobile ? product.title.split(' ').slice(0, 3).join(' ') : product.title}</span>
          </ProductTitle>
        </Link>
        <PriceWrapper>
          <ProductPrice
            price={currentProduct.price}
            compareAtPrice={currentProduct.compareAtPrice}
            currencySymbol={data.currency?.symbol}
            currencyCode={data.currency?.symbol === '$' ? data.currency?.isoCode : ''}
          />
        </PriceWrapper>
      </Flex>

      <FinalSale>{product.tags.includes('final-sale') && <span className={'final-sale'}>Final Sale</span>}</FinalSale>

      {currentProduct.color && <ColorText isInUpsell>Color: {currentProduct.color}</ColorText>}

      {relatedProducts && (
        <RelatedProducts isVisible={isHovered} isInUpsell>
          {relatedProducts.length > 1 &&
            relatedProducts.map((p, i) => {
              if (i < 5) {
                return (
                  <ButtonRaw
                    onClick={() => setCurrentProduct(p)}
                    tabIndex={-1}
                    key={i}
                    onMouseOver={() => setHoverColor(p.color)}
                    onMouseLeave={() => setHoverColor(null)}
                  >
                    <SwtachDot
                      isCurrent={currentProduct.color === p.color || hoverColor === p.color}
                      isSmall
                      swatchMap={productColors}
                      swatchName={p.color}
                      isInUpsell={true}
                    />
                  </ButtonRaw>
                )
              }
            })}
          {relatedProducts.length > 5 && (
            <ButtonBlock href={`/products/${currentProduct.handle}`} tabIndex={-1}>
              <span>+{relatedProducts.length - 5}</span>
            </ButtonBlock>
          )}
        </RelatedProducts>
      )}

      <ProductDetails
        product={currentProduct}
        callbackOnAdd={() => {
          setQuickBuyOpen(false)
          setHovered(false)
        }}
        isInQuickBuy
        isInUpsell
        isInCartPage={isInCartPage}
      />

      {/* <QuickBuyModal
        product={currentProduct}
        isOpen={isQuickBuyOpen}
        onRequestClose={() => {
          setQuickBuyOpen(false)
          setHovered(false)
        }}
      /> */}
    </Root>
  )
}

export default UpsellProductCard
