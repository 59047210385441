import React, { useEffect, useState, useCallback, Suspense } from 'react'
import dynamic from 'next/dynamic'

import useCart from '@/data/shopify/useCart'
import useCartModifier from '@/data/shopify/useCartModifier'

import styled from '@emotion/styled'

import { useUI } from '@/hooks/UI'

import { ButtonPrimary, ButtonSecondary } from '@button'
const Modal = dynamic(() => import('@/components/Modal'))
const LineItemSingle = dynamic(() => import('@/components/Cart/LineItemSingle'))
const LineItemBundle = dynamic(() => import('@/components/Cart/LineItemBundle'))
const ShippingProgressTracker = dynamic(() => import('@/components/ShippingProgressTracker/index'))
const TryNowCartWidget = dynamic(() => import('@/components/TryNow/CartWidget'))
const TryNowCartDisclaimer = dynamic(() => import('@/components/TryNow/CartDisclaimer'))

import formatPrice from '@/helpers/formatPrice'
import mapLineItemsToBundles from '@/helpers/mapLineItemsToBundles'

import { theme } from '@theme'
import { FitLoader } from '@/components/PageLoader'
import UpsellSlider from '../UpsellSlider'
import { AnalyticsListItemProvider } from '@/hooks/Analytics'

import { usePurpleDotCheckout } from '@purple-dot/purple-dot-react'
// import { loadCatchjs } from '@get-catch/catchjs'
import { useGlobalE } from '@/hooks/Global-E'
import useShowUpSell from '@/hooks/ShowUpSell'

const LedgerRowRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${theme.font.caps06}
  transition: opacity 200ms;
  .price {
    transition: opacity 100ms;
  }
  ${(p) => (p.isLoading ? '.price {opacity : 0.3 }' : '')}
`

const LedgerRow = ({ label, value, isLoading }) => {
  return (
    <LedgerRowRoot isLoading={isLoading}>
      <div>{label}</div>
      <div className={'price'}>{isLoading ? <FitLoader r={'23px'} /> : value}</div>
    </LedgerRowRoot>
  )
}

const EmptyBag = styled.div`
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  ${theme.font.body02}
`

const ProductGrid = styled.div`
  display: grid;
  ${theme.size.s('grid-gap')}
`

const Body = styled.div`
  overflow-y: auto;
  position: relative;
  height: 100%;
  ${theme.size.s('padding')}
  padding-top: 0 !important;
  ${(p) => (p.isDisabled ? 'opacity: 0.5; pointer-events: none;' : '')}
`
const Footer = styled.div`
  display: grid;
  border-top: 1px solid ${theme.colors.mono100};
  ${theme.size.s('padding')}
  ${theme.size.xs('grid-gap')}
  ${(p) => (p.isDisabled ? 'opacity: 0.5; pointer-events: none;' : '')}
  catch-callout:defined {
    justify-content: center;
  }
`
const UpsellWrap = styled.div`
  margin-top: 25px;
  overflow: hidden;
`
const UpsellTitle = styled.div`
  ${theme.font.caps07}
`
const Cart = () => {
  const { cart, loading } = useCart() || {}

  const shopifyCart = {
    items: cart
      ? cart.lineItems.map((lineItem) => {
          const properties = {}
          if (lineItem.customAttributes) {
            lineItem.customAttributes.forEach(({ key, value }) => {
              properties[key] = value
            })
          }
          return {
            id: lineItem.variant.id,
            quantity: lineItem.quantity,
            properties
          }
        })
      : []
  }
  const { preorderCheckout } = usePurpleDotCheckout({ cart: shopifyCart })

  const { isCartOpen, closeCart } = useUI()
  const [lineItems, setLineItems] = useState([])

  const cartModifier = useCartModifier()
  const { data } = useGlobalE()
  const { upSell, loading: loadingUpSell, upsellProducts } = useShowUpSell(data.country?.value) // upSell is the data from Contentful

  // useEffect(() => {
  //   if (data.country?.value == 'US') {
  //     _loadCatch()
  //   }
  // }, [data.country?.value])

  useEffect(() => {
    if (cart && cart.lineItems.length > 0) {
      setLineItems(mapLineItemsToBundles(cart.lineItems))
    }
    if (cart === null) {
      setLineItems([])
    }
  }, [cart])

  // const _loadCatch = useCallback(async () => {
  //   catchjs = await loadCatchjs({ live: true })
  //   catchjs
  //     .init('gw1Auhx9Tcr69cHyJw9wKEYM', {
  //       pageType: 'mini-cart',
  //       theme: 'light-color'
  //     })
  //     .then((res) => {
  //       console.log('************************')
  //       console.log('************************')
  //       console.log('Catch success on Cart : ', res)
  //       console.log('************************')
  //       console.log('************************')
  //     })
  //     .catch((err) => {
  //       console.log('************************')
  //       console.log('************************')
  //       console.log('Catch error on Cart : ', err)
  //       console.log('************************')
  //       console.log('************************')
  //     })
  // }, [])

  let itemsInBag = 0

  if (cart && cart.lineItems.length > 0) {
    cart.lineItems.forEach((i) => {
      itemsInBag = itemsInBag + i.quantity
    })
  }

  /* TryNow */
  let tnItems = 0
  let tnRemaining = 5

  let isTryNowCart = false
  if (cart && cart.lineItems.length > 0) {
    cart.lineItems.forEach((i) => {
      if (i.customAttributes && i.customAttributes.length > 0) {
        if (i.customAttributes.some((att) => att.key == 'tn_enabled' && att.value == 'true')) {
          isTryNowCart = true
          tnItems += i.quantity
        }
      }
    })
  }
  tnRemaining -= tnItems
  const shouldDisableUpsell = tnItems >= 1
  /* TryNow */

  const footer = cart && (
    <Footer isDisabled={cartModifier && cartModifier.isAnyModifierProcessing}>
      <LedgerRow
        label={`Estimated total`}
        value={formatPrice(
          isTryNowCart ? { amount: 0 } : { amount: cart.internationPricing?.totalPrice },
          data.currency?.symbol,
          data.currency?.symbol === '$' ? data.currency?.isoCode : ''
        )}
        isTotal
        isLoading={loading || (cartModifier && cartModifier.isAnyModifierProcessing)}
      />
      <ButtonSecondary href={'/bag'}>View Bag</ButtonSecondary>

      <ButtonPrimary
        href={
          isTryNowCart
            ? cart.checkoutUrl?.replace('splits-59.myshopify', 'shop.splits59')
            : preorderCheckout
            ? '/bag'
            : cart.checkoutUrl && cart.checkoutUrl
        }
        // disabled={cartModifier?.isAnyModifierProcessing}
      >
        Checkout
      </ButtonPrimary>

      {cart.subtotalPrice && data.country?.value == 'US' && (
        <div style={{ marginTop: '3%' }}>
          {/* <style jsx global>{`
            catch-callout:defined {
              justify-content: center;
            }
          `}
          </style> */}
          {/* <catch-callout
            price={isTryNowCart ? { amount: 0 } : cart.subtotalPrice.amount * 100}
            border-style="none-no-padding"
            theme="light-mono"
          /> */}
        </div>
      )}

      {isTryNowCart && data.country?.value == 'US' && <TryNowCartDisclaimer marginTop={0} />}
    </Footer>
  )

  return (
    <Modal
      isOpen={isCartOpen}
      onRequestClose={closeCart}
      title={
        cart === undefined
          ? 'Bag'
          : itemsInBag > 0
          ? itemsInBag > 1
            ? itemsInBag + ' items added to bag'
            : 'One item added to bag'
          : 'Your bag'
      }
      footer={footer}
      placement={'right'}
    >
      <Body isDisabled={cartModifier && cartModifier.isAnyModifierProcessing}>
        {cart?.subtotalPrice?.amount > 0 && data.country?.value == 'US' && (
          <ShippingProgressTracker
            isInMiniCart={true}
            totalPricePurchases={cart?.subtotalPrice.amount}
          ></ShippingProgressTracker>
        )}

        {cart === undefined && <FitLoader />}

        {cart === null && <EmptyBag>Your bag is empty.</EmptyBag>}

        {cart && lineItems.length > 0 && (
          <ProductGrid>
            {lineItems.map((lineItem, i) => {
              if (lineItem.bundle_ref) {
                return <LineItemBundle key={lineItem.bundle_ref + i} {...lineItem} />
              } else {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Suspense fallback={<FitLoader />}>
                    <LineItemSingle
                      key={lineItem.variant?.id + i}
                      lineItem={lineItem}
                      disabledAdd={tnRemaining <= 0 ? true : false}
                      isUsTryNow={data.country?.value == 'US' ? true : false}
                    />
                  </Suspense>
                )
              }
            })}
          </ProductGrid>
        )}
        {cart && lineItems.length > 0 && tnRemaining > 0 && tnRemaining < 5 && (
          <TryNowCartWidget itemsLeft={tnRemaining}></TryNowCartWidget>
        )}
        {upsellProducts &&
          upsellProducts?.length !== 0 &&
          upSell?.data?.upsellProduct?.showProducts &&
          !shouldDisableUpsell && (
            <UpsellWrap>
              {loadingUpSell ? (
                <FitLoader />
              ) : (
                <>
                  <UpsellTitle>
                    {upSell?.data?.upsellProduct?.title ? upSell?.data?.upsellProduct?.title : ''}
                  </UpsellTitle>
                  <AnalyticsListItemProvider list={'mini-cart'} position={1}>
                    <Suspense fallback={<FitLoader />}>
                      <UpsellSlider upsellProducts={upsellProducts} isSidebar />
                    </Suspense>
                  </AnalyticsListItemProvider>
                </>
              )}
            </UpsellWrap>
          )}
      </Body>
    </Modal>
  )
}

export default Cart
