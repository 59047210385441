import React from "react";

const IconLoyalty = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 224 191" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.001 190.201C109.898 190.22 107.813 189.813 105.871 189.005C103.929 188.198 102.17 187.006 100.701 185.501L17.6008 102.401C11.833 96.6483 7.29905 89.7798 4.27634 82.2152C1.25362 74.6506 -0.19436 66.549 0.0209227 58.4057C0.236205 50.2624 2.11023 42.2486 5.52841 34.8543C8.94658 27.4599 13.8371 20.8406 19.9008 15.4008C25.72 10.1727 32.5415 6.18237 39.9507 3.67251C47.36 1.16265 55.2022 0.185627 63.0008 0.800834C78.6786 1.8193 93.441 8.54242 104.501 19.7008L112.001 27.1008L121.601 17.6008C127.353 11.833 134.222 7.29905 141.786 4.27634C149.351 1.25363 157.453 -0.19436 165.596 0.0209226C173.739 0.236205 181.753 2.11023 189.147 5.52841C196.542 8.94659 203.161 13.8371 208.601 19.9008C213.829 25.72 217.819 32.5415 220.329 39.9508C222.839 47.36 223.816 55.2022 223.201 63.0008C222.182 78.6786 215.459 93.441 204.301 104.501L123.301 185.501C121.831 187.006 120.073 188.198 118.131 189.005C116.189 189.813 114.104 190.22 112.001 190.201ZM59.0008 16.7008C48.5661 16.5956 38.4574 20.3329 30.6008 27.2008C26.147 31.1958 22.5546 36.0572 20.0435 41.4878C17.5323 46.9183 16.1551 52.804 15.996 58.7849C15.8369 64.7658 16.8992 70.7164 19.1181 76.2728C21.3369 81.8292 24.6657 86.8747 28.9008 91.1008L117.701 179.901L112.001 174.201L193.001 93.2008C210.501 75.8008 212.101 47.7008 196.801 30.6008C192.806 26.147 187.944 22.5546 182.514 20.0435C177.083 17.5323 171.198 16.1551 165.217 15.996C159.236 15.8369 153.285 16.8992 147.729 19.1181C142.172 21.3369 137.127 24.6657 132.901 28.9008L117.701 44.1008C116.184 45.6031 114.136 46.4458 112.001 46.4458C109.866 46.4458 107.818 45.6031 106.301 44.1008L93.2008 31.0008C88.7195 26.4869 83.3924 22.9006 77.5241 20.4469C71.6558 17.9932 65.3614 16.7202 59.0008 16.7008Z" fill="white" />
            <path d="M146 57L158 69L102 125L66 89L78 77L102 101L146 57Z" fill="white" />
        </svg>
    );
};

export default IconLoyalty;