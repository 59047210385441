import { getCountry, getCountryFromIp, getCountryList, setShownPopup } from "@/store/features/globalE"
import { useEffect } from "react"
import { useDispatch ,  useSelector } from "react-redux"

// basic HOC

const withGlobalE = (props) => (WrappedComponent) => {
  function HOC(props) {
    // use all logic here
    const dispatch = useDispatch()
    const globalE = useSelector(state => state.global)
    // console.log("redux", globalE)
    useEffect(() => {
        
        if (!globalE.countriestList.length) {
            dispatch(getCountryList())
        }
        
        if (!globalE.country.value) {
            dispatch(getCountryFromIp())
        }
        
        if (!globalE.currency?.symbol) {
            dispatch(getCountry(globalE.country.value))
        }

        if (!globalE.shownPopup) {

            dispatch(setShownPopup(true))
        }
    }, [])

    return <WrappedComponent {...props} />
  }
  return HOC
}

export default withGlobalE
