import { graphqlQuery } from './services/shopifyApolloClient'
import gql from 'graphql-tag'
import { PRODUCT_FRAGMENT } from '@/data/shopify/services/graphql/productFragment'
import { mapProduct } from '@/data/shopify/mapHelpers'
import { counter } from 'react-uid/dist/es5/context'

const query = gql`
  ${PRODUCT_FRAGMENT}
  query collectionByHandle($handle: String! ,$country : CountryCode) @inContext(country: $country) {
    collectionByHandle(handle: $handle) {
      products(first: 200) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...ProductFragment
          }
        }
      }
    }
  }
`

const simpleSlugify = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
async function fetchProductsForUpsell(handle ,country) {
  const { data } = await graphqlQuery({
    query: query,
    variables: { handle , country }
  })
  if (!data.collectionByHandle) return null

  const products = data.collectionByHandle.products.edges.map((item) => mapProduct(item.node))

  const TITLE_SEPARATOR = ' - '

  const groups = []

  products.forEach((product) => {
    const titleBase = product.title.split(TITLE_SEPARATOR)[0]
    const _group = groups.find((g) => g.title === titleBase)
    if (_group) {
      _group.products.push(product)
    } else {
      groups.push({
        title: titleBase,
        id: simpleSlugify(titleBase),
        collectionHandle: handle,
        products: [product]
      })
    }
  })

  return groups
}

export default fetchProductsForUpsell
