import formatPrice from '../../helpers/formatPrice'

const ProductPrice = ({ price, compareAtPrice, showFromLabel, currencySymbol, currencyCode}) => {

  return compareAtPrice ? (
    <>
      {formatPrice(price , currencySymbol ,currencyCode)} <s>&nbsp;{formatPrice(compareAtPrice , currencySymbol, currencyCode)}&nbsp;</s>
    </>
  ) : (
    <>
      {showFromLabel ? 'From ' : ''}
      {formatPrice(price , currencySymbol, currencyCode)}
    </>
  )
}

export default ProductPrice