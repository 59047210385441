import React, { useCallback, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

const AnalyticsPageContext = React.createContext(null)
const AnalyticsListItemContext = React.createContext(null)

function AnalyticsPageContextProvider({ children, ...restProps }) {
  return <AnalyticsPageContext.Provider value={{ ...restProps }}>{children}</AnalyticsPageContext.Provider>
}
function AnalyticsListItemProvider({ children, list, position }) {
  return <AnalyticsListItemContext.Provider value={{ list, position }}>{children}</AnalyticsListItemContext.Provider>
}

const BRAND = 'SPLITS59'
const DEBUG = false
const logSyle = 'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;'

const log = (...args) => {
  console.log('%cuseAnalytics', logSyle, ...args)
}

const getProductIdFromProduct = (product, isFromSearchResults) => {
  if (isFromSearchResults) {
    return product.id;
  } else {
    return product.id.replace('gid://shopify/Product/', '')
  }
}

function useAnalytics() {
  const DEBUG = false

  const pageContext = useContext(AnalyticsPageContext)
  const listItemContext = useContext(AnalyticsListItemContext)
  const router = useRouter()

  useEffect(() => (window.dataLayer = window.dataLayer || []))

  const setCustomerData = useCallback((data) => {
    if (DEBUG) {
      log('setCustomerData', data)
    }

    window.dataLayer.push(data)
  }, [DEBUG])

  const pageView = useCallback(() => {
    let obj = {
      event: 'virtualPageview',
      pagePath: router.asPath,
      pageTitle: pageContext.pageTitle
    }

    if (pageContext?.pageType) {
      obj = {
        ...obj,
        ...pageContext
      }
    } else {
      obj = {
        ...obj,
        pageType: 'other',
        ecomm_pagetype: 'other'
      }
    }

    if (DEBUG) {
      log('pageView', obj)
    }

    window.dataLayer.push(obj)
  }, [DEBUG, pageContext, router.asPath])

  const productImpression = useCallback((product) => {
    if (listItemContext === null) {
      log('WARNING: productCard is placed outside of list item context')
    }
    const obj = {
      event: 'productImpression',
      ecomm_prodid: product.variants[0].sku,
      ecomm_pagetype: pageContext.pageType, // Values: home, category, product, cart, purchase, other
      ecomm_totalvalue: product.price.amount,
      content_ids: [getProductIdFromProduct(product, true)],
      ecommerce: {
        currencyCode: 'USD', // Local currency is optional.
        impressions: [
          {
            name: product.originalTitle, // Name or ID is required.
            id: product.variants[0].sku,
            price: product.price.amount,
            brand: product.vendor ?? BRAND,
            category: product.productType, //
            variant: product.color ?? '', //
            list: listItemContext.list, // List where the event happens: home, category, search, product, related products, cart
            position: listItemContext.position
          }
        ]
      }
    }
    if (DEBUG) log('productImpression', obj)

    window.dataLayer.push(obj)
  }, [DEBUG, listItemContext, pageContext?.pageType])

  const productClick = useCallback((product, isFromSearchResults) => {
    if (listItemContext === null) {
      console.warn('WARNING: productClick is placed outside of list item context')
    }

    const obj = {
      event: 'productClick',
      ecomm_prodid: product.variants[0].sku,
      ecomm_pagetype: pageContext.ecomm_pagetype ?? pageContext.pageType, // Values: home, category, product, cart, purchase.
      ecomm_totalvalue: product.price.amount,
      content_ids: [isFromSearchResults ? product.id : getProductIdFromProduct(product)],
      ecommerce: {
        currencyCode: 'USD', // Local currency
        click: {
          actionField: { list: listItemContext.list }, // List where the event happens: home, category, search, product, related products, cart
          products: [
            {
              name: product.originalTitle, // Name or ID is required.
              id: product.variants[0].sku,
              price: product.price.amount,
              brand: product.vendor ?? BRAND,
              category: product.productType, //
              variant: product.color ?? '', // It will be blank or undefined in home, category. Only available when a user selects a variant in product
              position: 1
            }
          ]
        }
      }
    }
    if (DEBUG) log('productClick', obj)
    window.dataLayer.push(obj)
  }, [DEBUG, listItemContext, pageContext?.ecomm_pagetype, pageContext?.pageType])

  const productViewDetails = useCallback((product) => {
    let obj
    if (product.bundlePieces && product.bundlePieces.length > 0) {
      obj = {
        event: 'productDetailView',
        pageType: 'product',
        ecomm_prodid: product.bundlePieces[0].variants[0].sku,
        ecomm_pagetype: pageContext.pageType, // Values: home, category, product, cart, purchase.
        ecomm_totalvalue: product.bundlePieces
          .reduce((acu, piece) => acu + parseFloat(piece.price.amount), 0)
          .toString(),
        content_ids: product.bundlePieces.map((piece) => getProductIdFromProduct(piece)),
        ecommerce: {
          currencyCode: 'USD', // Local currency
          detail: {
            actionField: { list: 'product' }, // 'detail' actions have an optional list property.
            products: product.bundlePieces.map((p) => ({
              name: p.originalTitle, // Name or ID is required.
              id: p.variants[0].sku,
              price: p.price.amount,
              brand: p.vendor ?? BRAND,
              category: p.productType, //
              variant: p.color ?? ''
            }))
          }
        }
      }
    } else {
      obj = {
        event: 'productDetailView',
        pageType: 'product',
        ecomm_prodid: product.variants[0].sku,
        ecomm_pagetype: 'product', // Values: home, category, product, cart, purchase.
        ecomm_totalvalue: product.price.amount,
        content_ids: [getProductIdFromProduct(product)],
        ecommerce: {
          currencyCode: 'USD', // Local currency
          detail: {
            actionField: { list: 'product' }, // 'detail' actions have an optional list property.
            products: [
              {
                name: product.originalTitle, // Name or ID is required.
                id: product.variants[0].sku,
                price: product.price.amount,
                brand: product.vendor ?? BRAND,
                category: product.productType, //
                variant: product.color ?? ''
              }
            ]
          }
        }
      }
    }

    if (DEBUG) log('productViewDetails', obj)
    window.dataLayer?.push(obj)
  }, [DEBUG, pageContext?.pageType])

  const addToCart = useCallback((variants, product, quantity = 1) => {
    variants.forEach((variant) => {
      const obj = {
        event: 'addToCart',
        ecomm_prodid: variant.sku,
        ecomm_pagetype: pageContext.pageType,
        ecomm_totalvalue: product.price.amount,
        content_ids: [getProductIdFromProduct(product)],
        ecommerce: {
          currencyCode: product.price.currencyCode,
          add: {
            actionField: { list: pageContext.pageType }, // home, category, search, product, related products
            products: [
              {
                name: product.originalTitle, // Name or ID is required.
                id: variant.sku,
                price: variant.price.amount,
                brand: product.vendor ?? BRAND,
                category: product.productType, //
                variant: variant.title, //
                quantity: quantity
              }
            ]
          }
        }
      }
      if (DEBUG) log('addToCart', obj)
      window.dataLayer.push(obj)
    })
  }, [DEBUG, pageContext?.pageType])

  const removeFromCart = useCallback((variants, quantity) => {
    variants.forEach((variant) => {
      const obj = {
        event: 'removeFromCart',
        content_ids: [getProductIdFromProduct(variant.merchandise.product)],
        ecommerce: {
          currencyCode: variant.merchandise.priceV2.currencyCode,
          remove: {
            products: [
              {
                name: variant.merchandise.product.title, // Name or ID is required.
                id: variant.merchandise.sku,
                price: variant.merchandise.priceV2.amount,
                currencyCode: variant.merchandise.priceV2.currencyCode,
                brand: 'SPLITS59',
                // category: variant.product.productType,
                variant: variant.merchandise.title,
                quantity: quantity
              }
            ]
          }
        }
      }
      if (DEBUG) log('removeFromCart', obj)
      window.dataLayer.push(obj)
    })
  }, [DEBUG])

  const quantityIncrease = useCallback((variants, quantity) => {
    variants.forEach((variant) => {
      const obj = {
        event: 'quantityIncrease',
        content_ids: [getProductIdFromProduct(variant.merchandise.product)],
        ecommerce: {
          currencyCode: variant.merchandise.priceV2.currencyCode,
          increase: {
            products: [
              {
                name: variant.merchandise.product.title, // Name or ID is required.
                id: variant.merchandise.sku,
                price: variant.merchandise.priceV2.amount,
                currencyCode: variant.merchandise.priceV2.currencyCode,
                brand: 'SPLITS59',
                // category: variant.product.productType,
                variant: variant.merchandise.title,
                quantity: 1
              }
            ]
          }
        }
      }
      if (DEBUG) log('quantityIncrease', obj)
      window.dataLayer.push(obj)
    })
  }, [DEBUG])

  
  const quantityDecrease = useCallback((variants, quantity) => {
    if (quantity == 1) {
      return null
    }
    variants.forEach((variant) => {
      const obj = {
        event: 'quantityDecrease',
        content_ids: [getProductIdFromProduct(variant.merchandise.product)],
        ecommerce: {
          currencyCode: variant.merchandise.priceV2.currencyCode,
          decrease: {
            products: [
              {
                name: variant.merchandise.product.title, // Name or ID is required.
                id: variant.merchandise.sku,
                price: variant.merchandise.priceV2.amount,
                currencyCode: variant.merchandise.priceV2.currencyCode,
                brand: 'SPLITS59',
                // category: variant.product.productType,
                variant: variant.merchandise.title,
                quantity: 1
              }
            ]
          }
        }
      }
      if (DEBUG) log('quantityDecrease', obj)
      window.dataLayer.push(obj)
    })
  }, [DEBUG])

  const newsletterSignup = useCallback(() => {
    window.dataLayer.push({
      event: 'newsletter',
      eventCategory: 'newsletter',
      eventAction: 'signup'
    })
  }, [])

  const search = useCallback((query) => {
    const obj = {
      event: 'search',
      pageType: pageContext.pageType,
      eventModel: {
        search_term: query
      }
    }

    if (DEBUG) log('search', obj)
    window.dataLayer.push(obj)
  }, [DEBUG, pageContext?.pageType])

  return {
    pageView,
    setCustomerData,
    productImpression,
    productClick,
    productViewDetails,
    addToCart,
    removeFromCart,
    quantityDecrease,
    quantityIncrease,
    newsletterSignup,
    search
  }
}

export { useAnalytics, AnalyticsPageContextProvider, AnalyticsListItemProvider }
