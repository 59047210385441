import { storefront } from '.'
export async function createCart(selectedCountry) {
  const value = selectedCountry?.value
  const createCartQuery = `
  mutation CreateCart ($value: CountryCode!) {
    cartCreate(input: {
      buyerIdentity: {
        countryCode: $value
      }
     
    }) {
      cart {
        buyerIdentity {
          countryCode
        }
        checkoutUrl
        id
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }  
`
  const response = await storefront(createCartQuery, { selectedCountry, value })
  if (!response.data) {
    return {
      statusCode: 500,
      body: JSON.stringify({ message: 'Error creating cart' })
    }
  }
  return {
    statusCode: 200,
    body: {
      cart: response.data.cartCreate.cart
    }
  }
}
