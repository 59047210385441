import fetchContentfulGraphQL from './fetchContentfulGraphQL'

function extract(response) {
  if (response?.data?.gwpProgress) {
    return {
      ...response.data.gwpProgress
    }
  } else {
    return null
  }
}


const query = `
    query {
        gwpProgress(id: "4poWRKtn4YSMuZlRs6gilD") {
            enableShippingProgress
            eligibilityOnEstimatedTotal
            progressMessageBeforePrice
            progressMessageAfterPrice
            successProgressMessage
        }
    }
`
async function fetchShippingProgress() {
  const response = await fetchContentfulGraphQL(query)
  return extract(response)
}

export default fetchShippingProgress
